<template>
	<div class="bg-gradient-to-b from-gray-50 to-white pb-10 py-24 px-4 lg:px-6 min-h-screen">
		<div class="max-w-7xl mx-auto lg:gap-x-5">
			<div class="flex justify-between px-5 items-center">
				<div class="member-pageheader">Store Products</div>
				<div>
					<button v-if="loggedIn" class="buttonprimary" @click="onCartClick()">Go to Cart</button>
				</div>
			</div>
			<div v-if="!loggedIn" class="bg-primary-900 text-white p-2 mt-6 text-sm font-medium rounded-md"><a href="/register">Please sign up to add products to your cart. Click here to sign up</a></div>
			<div class="grid grid-cols-6 max-w-7xl">
				<div class="col-span-6 object-contain" v-for="product in products" :key="product.productId">
					<StoreProduct :product="product" @addCartItem="onAddCartItem" @productDetails="onProductDetails" />
				</div>
			</div>
			<div>
				<ErrorMessages :errorMessages="errorMessages" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import StoreProduct from '@/components/controls/StoreProduct.vue';
import { useToast } from 'vue-toastification';
import { storeService } from '../../services';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';

export default {
	name: 'Store',
	inject: ['appInsights'],
	components: {
		StoreProduct,
		ErrorMessages,
	},
	data: () => {
		return {
			errorMessages: [],
			products: [],
		};
	},
	computed: {
		...mapGetters(['loggedIn', 'webAccountId']),
	},
	methods: {
		getProducts() {
			storeService.getProducts().then(
				(response) => {
					if (response.data.result) {
						this.products = response.data.data;
					}
				},
				(error) => {
					this.appInsights.trackException({
						exception: new Error(`getProducts:${error.message}`),
					});
				}
			);
		},
		onAddCartItem(productId, quantity) {
			this.errorMessages = [];

			storeService.saveCartItem(this.webAccountId, productId, quantity).then(
				(response) => {
					if (response.data.result) {
						useToast().success('Item added');

						this.$store.dispatch('updateCartItems', {
							cartItems: response.data.data,
						});
					} else {
						useToast().error('Add item failed.');

						this.errorMessages[0] = response.data.message;
					}
				},
				(error) => {
					useToast().error('Add item failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`onAddCartItem:${error.message}`),
					});
				}
			);
		},
		onProductDetails(productId) {
			this.$router.push({
				name: 'ProductDetail',
				query: {
					productId,
				},
			});
		},
		onCartClick() {
			this.$router.push('/cart');
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'Store' });
		this.getProducts();
	},
};
</script>
