import { createStore } from 'vuex';
import { webAccountModule } from './webAccount.module';
import { storeModule } from './store.module';

export default createStore({
	modules: {
		webAccountModule,
		storeModule,
	},
});
