import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

import Home from '../views/Home.vue';
//forms
import Contact from '../views/forms/Contact.vue';

// Resources Pages
import PrivacyPolicy from '../views/resources/PrivacyPolicy.vue';
import ActivationGuide from '../views/resources/ActivationGuide.vue';

//customer pages
import LogIn from '../views/customer/Login.vue';
import Register from '../views/customer/Register.vue';
import ResendConfirmRegistrationEmail from '@/views/customer/ResendConfirmRegistrationEmail.vue';
import CompleteRegistration from '@/views/customer/CompleteRegistration.vue';
import ForgotPassword from '@/views/customer/ForgotPassword.vue';
import ResetWebAccountPassword from '@/views/customer/ResetWebAccountPassword.vue';
import ConfirmRegistration from '@/views/customer/ConfirmRegistration.vue';
import PaymentCanceled from '@/views/customer/PaymentCanceled.vue';
import PaymentCompleted from '@/views/customer/PaymentCompleted.vue';
import StartCourse from '@/views/customer/StartCourse.vue';
import Store from '@/views/customer/Store.vue';
import ProductDetail from '@/views/customer/ProductDetail.vue';
import Account from '@/views/customer/AccountMain.vue';
import OrderCheckout from '@/views/customer/OrderCheckout.vue';
import Cart from '@/views/customer/Cart.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
		props: false,
		meta: { requiresAuth: false },
	},	
	{
		path: '/register',
		name: 'Register',
		component: Register,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/completeregistration',
		name: 'CompleteRegistration',
		component: CompleteRegistration,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/privacypolicy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/activationguide',
		name: 'ActivationGuide',
		component: ActivationGuide,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/confirmregistration',
		name: 'ConfirmRegistration',
		component: ConfirmRegistration,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/login',
		name: 'LogIn',
		component: LogIn,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/forgotpassword',
		name: 'ForgotPassword',
		component: ForgotPassword,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/resetwebaccountpassword',
		name: 'ResetWebAccountPassword',
		component: ResetWebAccountPassword,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/resendconfirmregistrationemail',
		name: 'ResendConfirmRegistrationEmail',
		component: ResendConfirmRegistrationEmail,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/startcourse',
		name: 'StartCourse',
		component: StartCourse,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/store',
		name: 'Store',
		component: Store,
		props: false,
		meta: { requiresAuth: false },
	},
	{
		path: '/productdetail',
		name: 'ProductDetail',
		component: ProductDetail,
		props: (route) => ({ productId: Number(route.query.productId) }),
		meta: { requiresAuth: false },
	},
	{
		path: '/account',
		name: 'Account',
		component: Account,
		props: false,
		meta: { requiresAuth: true },
	},
	{
		path: '/cart',
		name: 'Cart',
		component: Cart,
		props: false,
		meta: { requiresAuth: true },
	},
	{
		path: '/ordercheckout',
		name: 'OrderCheckout',
		component: OrderCheckout,
		props: false,
		meta: { requiresAuth: true },
	},
	{
		path: '/paymentcanceled',
		name: 'PaymentCanceled',
		component: PaymentCanceled,
		props: false,
		meta: { requiresAuth: true },
	},
	{
		path: '/paymentcompleted',
		name: 'PaymentCompleted',
		component: PaymentCompleted,
		props: false,
		meta: { requiresAuth: true },
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	const requiresAuth = to.meta.requiresAuth;
	if (!requiresAuth) {
		return next();
	}

	const isLoggedIn = store.state.webAccountModule.loggedIn;
	if (!isLoggedIn) {
		return next('/login');
	}

	const forcePasswordChangeOnNextLogin = store.state.webAccountModule.webAccount.forcePasswordChangeOnNextLogin;
	if (forcePasswordChangeOnNextLogin && to.path !== '/account') {
		return next('/account');
	}

	return next();
});

export default router;
