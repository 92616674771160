<template>
  <div class="justify-start space-x-8 mt-6 hidden lg:flex">
    <a
      href="https://www.facebook.com/DEAFinition-NPC-101269702447606"
      class="text-primary-800"
    >
      <span class="sr-only">Facebook</span>
      <svg
        class="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 
									1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clip-rule="evenodd"
        />
      </svg>
    </a>

    <a
      href="https://mobile.twitter.com/edeaf_sa"
      class="text-primary-800 hidden"
    >
      <span class="sr-only">Twitter</span>
      <svg
        class="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118
									4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 
									5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 
									0 012 18.407a11.616 11.616 0 006.29 1.84"
        />
      </svg>
    </a>

    <a
      href="https://www.linkedin.com/company/deafinition-npc/"
      class="text-primary-8000"
    >
      <span class="sr-only">Linked In</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        viewBox="0 0 144 144"
      >
        <path
          class="background"
          fill="#72c468"
          d="M 10.625,0 C 4.7611628,0 0,4.647 0,10.375 l 0,123.25 C 0,139.359 4.7611628,144 10.625,144 l 122.71875,0 C 139.21759,144 144,139.36 144,133.625 l 0,-123.25 
							C 144.00001,4.647 139.21759,0 133.34375,0 L 10.625,0 z m 21.40625,19.8125 c 6.828,0 12.375,5.57525 12.375,12.40625 0,6.837 -5.547,12.375 -12.375,12.375 -6.846,0 -12.375,-5.538 -12.375,-12.375 0.001,-6.83 5.53,-12.40625 12.375,-12.40625 
							z m 65.0625,32.46875 c 21.62801,0 25.625,14.21675 25.625,32.71875 l 0,37.71875 -21.34375,0 0,-33.4375 c 0,-7.969 -0.17199,-18.21875 -11.125,-18.21875 -11.113,0 
							-12.8125,8.69325 -12.8125,17.65625 l 0,34 -21.34375,0 0,-68.71875 20.5,0 0,9.375 
							0.28125,0 C 79.727,57.972 86.70275,52.28125 97.09375,52.28125 z M 21.34375,54 l 21.375,0 0,68.71875 -21.375,0 0,-68.71875 z"
        />
        <path
          class="foreground"
          fill="white"
          d="m 21.350995,53.985 h 21.359 v 68.722 h -21.359 V 53.985 z m 10.684,-34.16 c 6.828,0 12.377,5.549 
							12.377,12.38 0,6.837 -5.549,12.386 -12.377,12.386 -6.846,0 -12.387,-5.549 -12.387,-12.386 10e-4,-6.83 5.542,-12.38 12.387,-12.38"
        />
        <path
          class="foreground"
          fill="white"
          d="m 56.106995,53.985 h 20.485 v 9.39 h 0.286 c 2.852,-5.403 9.818,-11.099 20.209,-11.099 
							21.628005,0 25.621005,14.234 25.621005,32.736 v 37.694 h -21.34401 v -33.42 c 0,-7.969 -0.14599,-18.22 -11.098995,-18.22 -11.113,0 -12.819,8.681 -12.819,17.644 v 33.996 h -21.34 V 53.985 z"
        />
      </svg>
    </a>
  </div>
</template>