<template>
	<div>
		<h2 class="mt-4 text-2xl lg:text-3xl font-extrabold text-gray-900">Complete Payment</h2>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { storeService } from '../../services';
import { useToast } from 'vue-toastification';

export default {
	name: 'PaymentCompleted',
	inject: ['appInsights'],
	computed: {
		...mapGetters(['webAccountId']),
	},
	methods: {
		getCartItems() {
			storeService
				.getCartItems(this.webAccountId)
				.then((response) => {
					if (response.data.result) {
						this.$store.dispatch('updateCartItems', {
							cartItems: response.data.data,
						});
					} else {
						this.appInsights.trackException({
							exception: new Error(`updateCartItems:${response.data.message}`),
						});
					}
				})
				.catch((error) => {
					this.appInsights.trackException({
						exception: new Error(`getCartItems:${error.message}`),
					});
				});
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'PaymentCompleted' });
		this.getCartItems();

		useToast().success('Payment completed. Redirecting to account orders.');
		setTimeout(() => {
			this.$router.push('/account?viewOrders=true');
		}, 2000);
	},
};
</script>
