<template>
	<form class="divide-y divide-gray-200 lg:col-span-9 py-4" id="accountProfile" @submit.prevent="submit">
		<div class="py-6 px-4 sm:p-6 lg:pb-8">
			<div>
				<h2 class="member-pageheader">Profile</h2>
			</div>
			<div class="mt-6 grid grid-cols-12 gap-4">
				<div class="col-span-12 lg:col-span-6">
					<TextInput
						label="First Name"
						autocomplete="on"
						:autofocus="true"
						form="accountProfile"
						:maxlength="150"
						:minlength="1"
						name="firstName"
						placeholder="First Name"
						type="text"
						v-model="firstName"
						@update:model-value="firstName = $event"
					/>
				</div>
				<div class="col-span-12 lg:col-span-6">
					<TextInput
						label="Last Name"
						autocomplete="on"
						form="accountProfile"
						:maxlength="150"
						:minlength="1"
						name="lastName"
						placeholder="Last Name"
						type="text"
						v-model="lastName"
						@update:model-value="lastName = $event"
					/>
				</div>
				<div class="col-span-12 lg:col-span-6">
					<TextInput
						label="Address Line 1"
						autocomplete="on"
						form="accountProfile"
						:maxlength="150"
						:minlength="1"
						name="AddressLine1"
						placeholder="Address Line 1"
						:required="false"
						type="text"
						v-model="addressLine1"
						@update:model-value="addressLine1 = $event"
					/>
				</div>
				<div class="col-span-12 lg:col-span-6">
					<DisplayField :inputStyle="true" label="Email Address" v-model="emailAddress" />
				</div>
				<div class="col-span-12 lg:col-span-6">
					<TextInput
						label="Address Line 2"
						autocomplete="on"
						form="accountProfile"
						:maxlength="150"
						name="AddressLine2"
						placeholder="Address Line 2"
						:required="false"
						type="text"
						v-model="addressLine2"
						@update:model-value="addressLine2 = $event"
					/>
				</div>
				<div class="col-span-12 lg:col-span-2">
					<TextInput
						label="Date of Birth"
						form="accountProfile"
						:max="maxDateOfBirth"
						:min="minDateOfBirth"
						name="dateOfBirth"
						:required="false"
						type="date"
						v-model="dateOfBirth"
						@update:model-value="dateOfBirth = $event"
					/>
				</div>
				<div class="col-span-12 lg:col-span-4">
					<TextInput
						label="Contact Number"
						autocomplete="on"
						form="accountProfile"
						:maxlength="150"
						name="contactNumber"
						placeholder="Contact Number"
						:required="false"
						type="tel"
						v-model="contactNumber"
						@update:model-value="contactNumber = $event"
					/>
				</div>
				<div class="col-span-12 lg:col-span-4">
					<TextInput
						label="Address Line 3"
						autocomplete="on"
						form="accountProfile"
						:maxlength="150"
						name="AddressLine3"
						placeholder="Address Line 3"
						:required="false"
						type="text"
						v-model="addressLine3"
						@update:model-value="addressLine3 = $event"
					/>
				</div>
				<div class="col-span-12 lg:col-span-2">
					<TextInput
						label="Post Code"
						autocomplete="on"
						form="accountProfile"
						:maxlength="5"
						:minlength="1"
						name="PostCode"
						placeholder="Post Code"
						:required="false"
						type="text"
						v-model="postCode"
						@update:model-value="postCode = $event"
					/>
				</div>
				<div class="col-span-6 md:col-span-6">
					<MultiSelect
						controlLabel="Province"
						:hideSelected="false"
						id="provinceId"
						label="name"
						:multiple="false"
						:options="provinces"
						:showLabels="false"
						trackBy="provinceId"
						v-model="selectedProvince"
						@update:model-value="selectedProvince = $event"
					/>
				</div>
				<div class="col-span-12">
					<SwitchGroup as="li" class="py-3 flex items-center justify-left">
						<div class="flex flex-col">
							<SwitchLabel as="p" class="text-sm font-medium text-gray-900" passive> This is a Company Account </SwitchLabel>
						</div>
						<Switch
							v-model="companyAccount"
							:class="[
								companyAccount ? 'bg-primary-500' : 'bg-gray-200',
								'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
							]"
						>
							<span
								aria-hidden="true"
								:class="[
									companyAccount ? 'translate-x-5' : 'translate-x-0',
									'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
								]"
							/>
						</Switch>
					</SwitchGroup>
				</div>
				<div v-if="companyAccount" class="col-span-12">
					<div class="grid grid-cols-12 gap-4">
						<div class="col-span-12 lg:col-span-6">
							<TextInput
								label="Company Name"
								autocomplete="on"
								form="accountProfile"
								:maxlength="150"
								name="companyName"
								placeholder="Company Name"
								:required="false"
								type="text"
								v-model="companyName"
								@update:model-value="companyName = $event"
							/>
						</div>
						<div class="col-span-12 lg:col-span-6">
							<TextInput
								label="Company Registration Number"
								autocomplete="on"
								form="accountProfile"
								:maxlength="150"
								name="companyRegistrationNumber"
								placeholder="Company Registration Number"
								:required="false"
								type="text"
								v-model="companyRegistrationNumber"
								@update:model-value="companyRegistrationNumber = $event"
							/>
						</div>
						<div class="col-span-12 lg:col-span-6">
							<TextInput
								label="Vat Number"
								autocomplete="on"
								form="accountProfile"
								:maxlength="150"
								name="vatNumber"
								placeholder="Vat Number"
								:required="false"
								type="text"
								v-model="vatNumber"
								@update:model-value="vatNumber = $event"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="divide-y divide-gray-200">
			<div class="py-4 px-4 flex justify-end sm:px-6">
				<button
					type="submit"
					:disabled="submitted"
					class="
						ml-5
						bg-primary-700
						border border-transparent
						rounded-md
						shadow-sm
						py-2
						px-4
						inline-flex
						justify-center
						text-sm
						font-medium
						text-white
						hover:bg-primary-800
						focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
					"
				>
					Save
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import { parseISO, format } from 'date-fns';
import TextInput from '../../components/controls/TextInput.vue';
import MultiSelect from '../../components/controls/MultiSelect.vue';
import DisplayField from '../../components/controls/DisplayField.vue';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

export default {
	name: 'AccountProfile',
	emits: ['updateProfile'],
	components: {
		TextInput,
		MultiSelect,
		DisplayField,
		Switch,
		SwitchGroup,
		SwitchLabel,
	},
	props: {
		submitted: {
			type: Boolean,
			required: true,
		},
		profile: {
			type: Object,
			required: true,
		},
		provinces: {
			type: Array,
			required: true,
		},
	},
	data: () => {
		return {
			emailAddress: null,
			selectedProvince: {},
			firstName: null,
			lastName: null,
			dateOfBirth: null,
			contactNumber: null,
			addressLine1: null,
			addressLine2: null,
			addressLine3: null,
			postCode: null,
			companyAccount: null,
			companyName: null,
			companyRegistrationNumber: null,
			vatNumber: null,
		};
	},
	computed: {
		maxDateOfBirth() {
			return new Date(); // current date
		},
		minDateOfBirth() {
			let currentdate = new Date();
			return new Date(currentdate.setMonth(currentdate.getMonth() - 1440)); // current date - 120 years
		},
	},
	watch: {
		profile: {
			handler(profile) {
				if (profile) {
					this.emailAddress = profile.emailAddress;
					this.selectedProvince = { provinceId: profile.provinceId, name: profile.province };
					this.firstName = profile.firstName;
					this.lastName = profile.lastName;
					this.dateOfBirth = profile.dateOfBirth ? format(parseISO(profile.dateOfBirth), 'yyyy-MM-dd') : null;
					this.contactNumber = profile.contactNumber;
					this.addressLine1 = profile.addressLine1;
					this.addressLine2 = profile.addressLine2;
					this.addressLine3 = profile.addressLine3;
					this.postCode = profile.postCode;
					this.companyAccount = profile.companyAccount;
					this.companyName = profile.companyName;
					this.companyRegistrationNumber = profile.companyRegistrationNumber;
					this.vatNumber = profile.vatNumber;
				}
			},
			immediate: true,
		},
	},
	methods: {
		submit(event) {
			event.preventDefault();

			this.$emit('updateProfile', {
				provinceId: this.selectedProvince ? this.selectedProvince.provinceId : null,
				firstName: this.firstName,
				lastName: this.lastName,
				dateOfBirth: this.dateOfBirth ? this.dateOfBirth : null,
				contactNumber: this.contactNumber,
				addressLine1: this.addressLine1,
				addressLine2: this.addressLine2,
				addressLine3: this.addressLine3,
				postCode: this.postCode,
				companyAccount: this.companyAccount,
				companyName: this.companyName,
				companyRegistrationNumber: this.companyRegistrationNumber,
				vatNumber: this.vatNumber,
			});
		},
	},
};
</script>
