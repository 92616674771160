<template>
	<div class="bg-gradient-to-b from-gray-50 to-white pb-10 py-24 lg:py-32 px-4 lg:px-6 min-h-screen">
		<div class="max-w-7xl mx-auto lg:gap-x-5">
			<div class="grid grid-cols-6 max-w-7xl border border-gray-200 shadow-sm">
				<div class="col-span-6 xl:col-span-4 bg-white px-4 sm:px-10 py-10">
					<div class="flex border-b pb-5 justify-between">
						<div class="member-pageheader">Shopping Cart</div>
						<div class="member-pageheader">{{ cartQuantity }} Items</div>
					</div>
					<div v-if="cartItems.length == 0" class="font-semibold text-gray-600 py-10">Your cart is currently empty. Click on 'Continue Shopping' below to view products.</div>
					<div v-for="cartItem in cartItems" :key="cartItem.productId" class="flex items-center -mx-8 px-6 py-5">
						<CartItem :cartItem="cartItem" :maximumQuantity="maximumCartItemQuantity" @addCartItem="onAddCartItem" @removeCartItem="onRemoveCartItem" />
					</div>
					<div class="w-full sm:flex justify-between">
						<div>
							<button class="buttonsecondary w-full sm:w-auto my-4 sm:my-0" @click="onContinueShopping">
								<ArrowCircleLeftIcon class="h-4 w-4 mr-2" />
								Continue Shopping
							</button>
						</div>
						<div>
							<button class="buttonprimary w-full sm:w-auto" @click="onClearCart">
								<TrashIcon class="h-4 w-4 mr-2" />
								Remove all items
							</button>
						</div>
					</div>
					<!-- <div class="text-xs font-medium text-gray-500 pt-6">Please note that, due to the sensitive nature of these items, we cannot send them via standard post.</div> -->
				</div>
				<div id="summary" class="border-l border-gray-200 bg-blue-50 col-span-6 xl:col-span-2 px-8 py-10">
					<h1 class="member-pageheader border-b pb-5">Order Summary</h1>
					<div class="flex justify-between mt-10 mb-5">
						<span class="font-semibold text-sm uppercase">{{ cartQuantity }} Items</span>
						<span class="font-semibold text-sm">R {{ cartTotal }}</span>
					</div>
					<div class="border-t mt-8">
						<div class="flex font-semibold justify-between py-6 text-sm uppercase">
							<span>Total cost</span>
							<span>R {{ finalPaymentAmount }}</span>
						</div>
						<button :disabled="cartItems.length == 0" :class="{ 'opacity-50 cursor-not-allowed': cartItems.length == 0 }" class="buttonprimary mt-4 w-full" @click="onCheckout">
							Checkout
						</button>
					</div>
				</div>
				<div>
					<ErrorMessages :errorMessages="errorMessages" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import CartItem from '@/components/controls/CartItem.vue';
import { useToast } from 'vue-toastification';
import { storeService } from '../../services';
import { TrashIcon, ArrowCircleLeftIcon } from '@heroicons/vue/outline';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';

export default {
	name: 'Cart',
	inject: ['appInsights'],
	components: {
		CartItem,
		TrashIcon,
		ArrowCircleLeftIcon,
		ErrorMessages,
	},
	data: () => {
		return {
			errorMessages: [],
			products: [],
			deliveryCharges: null,
		};
	},
	computed: {
		...mapGetters(['webAccountId', 'cartItems', 'cartTotal', 'cartQuantity']),
		finalPaymentAmount() {
			return parseFloat(this.cartTotal).toFixed(2);
		},
		maximumCartItemQuantity() {
			return parseInt(process.env.VUE_APP_DEAFINITION_MAXIMUM_CART_ITEM_QUANTITY);
		},
	},
	methods: {
		onAddCartItem(productId, quantity) {
			this.errorMessages = [];

			if (productId > 0) {
				storeService.saveCartItem(this.webAccountId, productId, quantity).then(
					(response) => {
						if (response.data.result) {
							useToast().success('Item added');

							this.$store.dispatch('updateCartItems', {
								cartItems: response.data.data,
							});
						} else {
							useToast().error('Add item failed.');

							this.errorMessages[0] = response.data.message;
						}
					},
					(error) => {
						useToast().error('Add item failed.');

						if (error.response && error.response.data && error.response.data.data) {
							this.errorMessages = error.response.data.data.slice();
						}

						this.appInsights.trackException({
							exception: new Error(`onAddCartItem:${error.message}`),
						});
					}
				);
			}
		},
		onRemoveCartItem(productId, quantity) {
			this.errorMessages = [];

			if (productId > 0) {
				if (quantity > 0) {
					this.onAddCartItem(productId, -1);
				} else {
					storeService.removeCartItem(this.webAccountId, productId).then(
						(response) => {
							if (response.data.result) {
								useToast().success('Item removed');

								this.$store.dispatch('updateCartItems', {
									cartItems: response.data.data,
								});
							} else {
								useToast().error('Remove item failed.');

								this.errorMessages[0] = response.data.message;
							}
						},
						(error) => {
							useToast().error('Remove item failed.');
							
							if (error.response && error.response.data && error.response.data.data) {
								this.errorMessages = error.response.data.data.slice();
							}

							this.appInsights.trackException({
								exception: new Error(`onRemoveCartItem:${error.message}`),
							});
						}
					);
				}
			}
		},
		onContinueShopping() {
			this.$router.push('/store');
		},
		onClearCart() {
			this.cartItems.forEach((cartItem) => {
				if (cartItem.productId > 0) {
					this.onRemoveCartItem(cartItem.productId, 0);
				}
			});
		},
		onCheckout() {
			this.$router.push('/ordercheckout');
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'Cart' });
	},
};
</script>
