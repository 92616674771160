import axios from 'axios';

function registerWebAccount(emailAddress, password, confirmPassword, firstName, lastName, companyAccount, companyName) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/RegisterWebAccount`;
	const data = JSON.stringify({
		emailAddress,
		password,
		confirmPassword,
		firstName,
		lastName,
		companyAccount,
		companyName,
		forcePasswordChangeOnNextLogin: false,
	});

	return axios({
		method: 'post',
		url,
		data,
	});
}

function completeRegistration(registrationKey) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/CompleteRegistration`;
	const data = JSON.stringify(registrationKey);

	return axios({
		method: 'post',
		url,
		data,
	});
}

function resendConfirmRegistrationEmail(emailAddress) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/ResendConfirmRegistrationEmail`;
	const data = JSON.stringify(emailAddress);

	return axios({
		method: 'post',
		url,
		data,
	});
}

function forgotPassword(emailAddress) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/ForgotPassword`;
	const data = JSON.stringify(emailAddress);

	return axios({
		method: 'post',
		url,
		data,
	});
}

function resetWebAccountPassword(registrationKey) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/ResetWebAccountPassword`;
	const data = JSON.stringify(registrationKey);

	return axios({
		method: 'post',
		url,
		data,
	});
}

function authenticateWebAccount(emailAddress, password) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/AuthenticateWebAccount`;
	const data = JSON.stringify({ emailAddress, password });

	return axios({
		method: 'post',
		url,
		data,
	});
}

function changeWebAccountPassword(webAccountId, newPassword, confirmNewPassword) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/ChangeWebAccountPassword`;
	const data = JSON.stringify({ webAccountId, newPassword, confirmNewPassword });

	return axios({
		method: 'post',
		url,
		data,
	});
}

function changeWebAccountEmailAddress(webAccountId, emailAddress) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/ChangeWebAccountEmailAddress`;
	const data = JSON.stringify({ webAccountId, emailAddress });

	return axios({
		method: 'post',
		url,
		data,
	});
}

function sendEmail(firstName, lastName, emailAddress, subject, message) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/SendEmail`;
	const data = {
		subject: subject,
		content: `FirstName: ${firstName}\nLastName: ${lastName}\nEmail: ${emailAddress}\nMessage: ${message}`,
		source: 'Deafinition.Web',
	};

	return axios({
		method: 'post',
		url,
		data,
	});
}

function getWebAccount(webAccountId) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/GetWebAccount`;
	const params = { webAccountId };

	return axios({
		method: 'get',
		url,
		params,
	});
}

function updateWebAccount(
	webAccountId,
	provinceId,
	firstName,
	lastName,
	dateOfBirth,
	contactNumber,
	addressLine1,
	addressLine2,
	addressLine3,
	postCode,
	companyAccount,
	companyName,
	companyRegistrationNumber,
	vatNumber
) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}WebAccount/UpdateWebAccount`;
	const data = JSON.stringify({
		webAccountId,
		provinceId,
		firstName,
		lastName,
		dateOfBirth,
		contactNumber,
		addressLine1,
		addressLine2,
		addressLine3,
		postCode,
		companyAccount,
		companyName,
		companyRegistrationNumber,
		vatNumber,
	});

	return axios({
		method: 'post',
		url,
		data,
	});
}

export const webAccountService = {
	registerWebAccount,
	completeRegistration,
	resendConfirmRegistrationEmail,
	forgotPassword,
	resetWebAccountPassword,
	authenticateWebAccount,
	changeWebAccountPassword,
	changeWebAccountEmailAddress,
	sendEmail,
	getWebAccount,
	updateWebAccount,
};

export default webAccountService;
