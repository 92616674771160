<template>
	<Navbar />
	<main class="pt-10">
		<router-view />
		<Footer />
	</main>
</template>

<script>
import Footer from './components/Footer.vue';
import Navbar from './components/Navbar.vue';

export default {
	name: 'App',
	components: { Navbar, Footer },
};
</script>
