<template>
	<form class="divide-y divide-gray-200 lg:col-span-9 py-4" id="accountSettings" @submit.prevent="submit">
		<div class="py-6 px-4 sm:p-6 lg:pb-8">
			<div>
				<h2 class="member-pageheader">Account Settings</h2>
			</div>
			<div v-if="!forcePasswordChangeOnNextLogin" class="bg-white px-4 py-5 sm:rounded-lg sm:p-6 max-w-7xl mx-auto">
				<div class="md:grid md:grid-cols-3 md:gap-4">
					<div class="md:col-span-3">
						<h3 class="font-medium leading-6 text-gray-900">Change Email Address</h3>
					</div>
					<div class="mt-2 md:mt-0 md:col-span-3">
						<div class="grid grid-cols-6 gap-6">
							<div class="col-span-6 sm:col-span-4">
								<TextInput
									autocomplete="on"
									form="accountSettings"
									:maxlength="150"
									:minlength="5"
									name="emailAddress"
									placeholder="Email Address"
									type="email"
									v-model="emailAddress"
									@update:model-value="emailAddress = $event"
								/>
							</div>
							<div class="py-2 text-right">
								<button :disabled="submitted" class="whitespace-nowrap buttonprimary" @click="changeEmailAddress($event)">Change Email Address</button>
							</div>
						</div>
						<div class="mt-1 text-sm text-gray-500 col-span-6">
							* Note: For security reasons this action will log you out and a new Registration Confirmation email will be sent to your new email address.
							<br />
							You will not be able to login again until your new email address is confirmed.
						</div>
					</div>
				</div>
			</div>
			<div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6 max-w-7xl mx-auto">
				<div class="md:grid md:grid-cols-3 md:gap-4">
					<div class="md:col-span-3">
						<h3 class="font-medium leading-6 text-gray-900">Change Passsword</h3>
					</div>
					<div class="mt-2 md:mt-0 md:col-span-3">
						<div class="grid grid-cols-6 gap-6">
							<div class="col-span-6 sm:col-span-4">
								<TextInput
									form="accountSettings"
									:maxlength="15"
									:minlength="5"
									name="newPassword"
									placeholder="New password"
									type="password"
									v-model="newPassword"
									@update:model-value="newPassword = $event"
								/>
							</div>
							<div class="col-span-6 sm:col-span-4">
								<TextInput
									form="accountSettings"
									:maxlength="15"
									:minlength="5"
									name="confirmNewPassword"
									placeholder="Confirm new password"
									type="password"
									v-model="confirmNewPassword"
									@update:model-value="confirmNewPassword = $event"
								/>
							</div>
							<div class="py-2 text-right">
								<button :disabled="submitted" class="whitespace-nowrap buttonprimary" @click="changePassword($event)">Change Password</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import TextInput from '../../components/controls/TextInput.vue';

export default {
	name: 'AccountSettings',
	emits: ['changeEmailAddress', 'changePassword'],
	components: {
		TextInput,
	},
	props: {
		submitted: {
			type: Boolean,
			required: true,
		},
		forcePasswordChangeOnNextLogin: {
			type: Boolean,
			required: true,
		},
	},
	data: () => {
		return {
			emailAddress: null,
			newPassword: null,
			confirmNewPassword: null,
		};
	},
	methods: {
		changeEmailAddress() {
			this.$emit('changeEmailAddress', this.emailAddress);
		},
		changePassword() {
			this.$emit('changePassword', this.newPassword, this.confirmNewPassword);
		},
	},
};
</script>
