<template>
	<form class="divide-y divide-gray-200 lg:col-span-9 py-4" id="accountCourses" @submit.prevent="submit">
		<div class="py-6 sm:p-6 lg:pb-8">
			<div>
				<h2 class="member-pageheader">Online Courses</h2>
			</div>
			<div class="bg-white px-4 py-5 sm:rounded-lg sm:p-3 max-w-7xl mx-auto">
				<div class="md:grid grid-cols-10 py-3">
					<div class="col-span-6">
						<h3 class="sm:text-lg font-medium leading-6 text-gray-900">Course Details</h3>
						<p class="mt-1 text-sm text-gray-500">Details of your online courses</p>
					</div>
					<div class="mt-5 md:mt-0 col-span-10 pb-6">
						<div class="flex flex-col">
							<div class="-my-2 sm:-mx-6 lg:-mx-8">
								<div class="py-2 align-middle inline-block lg:min-w-full sm:px-4 lg:px-8">
									<div class="border border-gray-200 mt-3 rounded shadow-sm overflow-hidden">
										<table class="lg:min-w-full divide-y divide-gray-200">
											<thead class="bg-gray-50">
												<tr>
													<th v-for="header in courseHeaders" :key="header.name" :class="header.class" scope="col" class="table-header">
														{{ header.name }}
													</th>
												</tr>
											</thead>
											<tbody>
												<tr :class="getRowColor(i)" v-for="(course, i) in courses" :key="i">
													<td class="table-item hidden xl:table-cell">
														<div class="items-center flex">
															<div>{{ course.orderId }}</div>
														</div>
													</td>
													<td class="table-item">
														<div class="flex items-center">
															<div class="mr-2">{{ `${getDateString(course.paymentDate)}` }}</div>
														</div>
													</td>
													<td class="table-item hidden sm:table-cell">
														<div class="items-center flex w-32 lg:w-60 xl:w-full truncate">
															<div>{{ course.product }}</div>
														</div>
													</td>
													<td class="table-item hidden sm:table-cell">
														<div class="items-center flex">
															<div>{{ course.emailAddress }}</div>
														</div>
													</td>
													<td class="table-item hidden lg:table-cell">
														<div class="flex items-center">
															<div class="mr-2">{{ `${getDateString(course.expiryDate)}` }}</div>
														</div>
													</td>
													<td class="table-item">
														<div v-if="!course.activated" class="flex items-center">
															<button :disabled="activationOpen || submitted" class="whitespace-nowrap buttonprimary" @click="showCourseActivation(course)">Activate</button>
														</div>
														<div v-if="course.activated" class="flex items-center">
															<button :disabled="detailsOpen || submitted" class="whitespace-nowrap buttonsecondary" @click="showCourseDetails(course)">View Details</button>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<TransitionRoot as="template" :show="activationOpen">
				<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="activationOpen = false" :open="activationOpen">
					<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<TransitionChild enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
							<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</TransitionChild>

						<!-- This element is to trick the browser into centering the modal contents. -->
						<span class="hidden" aria-hidden="true">&#8203;</span>
						<TransitionChild
							enter="ease-out duration-300"
							enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enter-to="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leave-from="opacity-100 translate-y-0 sm:scale-100"
							leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div
								class="
									inline-block
									align-bottom
									bg-white
									rounded-lg
									px-4
									mt-32
									pt-5
									pb-4
									text-left
									overflow-hidden
									shadow-xl
									transform
									transition-all
									sm:align-middle sm:max-w-3xl sm:w-full sm:p-6
								"
							>
								<div>
									<div class="text-center">
										<DialogTitle class="text-gray-900 px-2 text-sm">
											<div class="flex justify-between items-center">
												<div class="w-2/3 flex items-center">
													<img :src="'images/deafinition_logo_pic.png'" class="pr-2" />
													<img :src="'images/deafinition_text_logo.png'" class="pr-2" />
												</div>
												<div class="md:grid md:grid-cols-3 gap-1 text-left text-xs hidden border tracking-tighter p-2 rounded bg-gray-50">
													<div class="text-gray-900 font-medium">Order ID:</div>
													<div class="col-span-2">{{ selectedCourse.orderId }}</div>
													<div class="text-gray-900 font-medium">Date:</div>
													<div class="col-span-2">{{ `${getDateString(selectedCourse.paymentDate)}` }}</div>
													<div class="text-gray-900 font-medium">Product:</div>
													<div class="col-span-2">{{ selectedCourse.product }}</div>
													<div class="text-gray-900 font-medium">Provider:</div>
													<div class="col-span-2">{{ selectedCourse.productProvider }}</div>
												</div>
											</div>
										</DialogTitle>
									</div>
									<div class="mt-4 grid grid-cols-12 gap-4">
										<div class="col-span-12 member-pageheader">Activate Course</div>
										<div class="col-span-12 lg:col-span-6">
											<TextInput
												label="First Name"
												autocomplete="on"
												:autofocus="true"
												form="accountProfile"
												:maxlength="150"
												:minlength="1"
												name="firstName"
												placeholder="First Name"
												type="text"
												v-model="selectedCourse.firstName"
												@update:model-value="selectedCourse.firstName = $event"
											/>
										</div>
										<div class="col-span-12 lg:col-span-6">
											<TextInput
												label="Last Name"
												autocomplete="on"
												form="accountProfile"
												:maxlength="150"
												:minlength="1"
												name="lastName"
												placeholder="Last Name"
												type="text"
												v-model="selectedCourse.lastName"
												@update:model-value="selectedCourse.lastName = $event"
											/>
										</div>
										<div class="col-span-12">
											<TextInput
												label="Email Address"
												autocomplete="on"
												:maxlength="150"
												:minlength="5"
												name="emailAddress"
												placeholder="Email Address"
												type="email"
												v-model="selectedCourse.emailAddress"
												@update:model-value="selectedCourse.emailAddress = $event"
											/>
										</div>
									</div>
									<div class="pt-6 text-right">
										<button type="button" :disabled="submitted" class="whitespace-nowrap buttonprimary" @click="activateCourse(selectedCourse)">Activate</button>
										<button type="button" class="buttonsecondary w-24 ml-4" @click="activationOpen = false">Close</button>
									</div>
								</div>
							</div>
						</TransitionChild>
					</div>
				</Dialog>
			</TransitionRoot>
			<TransitionRoot as="template" :show="detailsOpen">
				<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="detailsOpen = false" :open="detailsOpen">
					<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<TransitionChild enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
							<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</TransitionChild>

						<!-- This element is to trick the browser into centering the modal contents. -->
						<span class="hidden" aria-hidden="true">&#8203;</span>
						<TransitionChild
							enter="ease-out duration-300"
							enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enter-to="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leave-from="opacity-100 translate-y-0 sm:scale-100"
							leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div
								class="
									inline-block
									align-bottom
									bg-white
									rounded-lg
									px-4
									mt-32
									pt-5
									pb-4
									text-left
									overflow-hidden
									shadow-xl
									transform
									transition-all
									sm:align-middle sm:max-w-3xl sm:w-full sm:p-6
								"
							>
								<div>
									<div class="text-center">
										<DialogTitle class="text-gray-900 px-2 text-sm">
											<div class="flex justify-between items-center">
												<div class="w-2/3 flex items-center">
													<img :src="'images/deafinition_logo_pic.png'" class="pr-2" />
													<img :src="'images/deafinition_text_logo.png'" class="pr-2" />
												</div>
											</div>
										</DialogTitle>
									</div>
									<div class="mt-4 grid grid-cols-12 gap-4">
										<div class="col-span-12 member-pageheader">Course Details</div>
										<div class="col-span-12 lg:col-span-6">
											<div class="formlabel">Payment Date</div>
											<div class="displaytext">{{ `${getDateString(selectedCourse.paymentDate)}` }}</div>
										</div>
										<div class="col-span-12 lg:col-span-6">
											<div class="formlabel">Activation Date</div>
											<div class="displaytext">{{ `${getDateString(selectedCourse.activatedDate)}` }}</div>
										</div>
										<div class="col-span-12 lg:col-span-4">
											<div class="formlabel">First Name</div>
											<div class="displaytext">{{ selectedCourse.firstName }}</div>
										</div>
										<div class="col-span-12 lg:col-span-4">
											<div class="formlabel">Last Name</div>
											<div class="displaytext">{{ selectedCourse.lastName }}</div>
										</div>
										<div class="col-span-12 lg:col-span-4">
											<div class="formlabel">Email Address</div>
											<div class="displaytext">{{ selectedCourse.emailAddress }}</div>
										</div>
										<div class="col-span-12">
											<div class="formlabel">Course Name</div>
											<div class="displaytext">{{ selectedCourse.product }}</div>
										</div>
										<div class="col-span-12 lg:col-span-6">
											<div class="formlabel">Provider</div>
											<div class="displaytext">{{ selectedCourse.productProvider }}</div>
										</div>
										<div class="col-span-12 lg:col-span-6">
											<div class="formlabel">Expiry Date</div>
											<div class="displaytext">{{ `${getDateString(selectedCourse.expiryDate)}` }}</div>
										</div>
										<div class="col-span-12">
											<div class="formlabel font-semibold">Go to your selected course or copy the course link using the buttons below:</div>
										</div>
										<div class="col-span-12 sm:col-span-4">
											<button type="button" v-if="!selectedCourse.expired" :disabled="submitted" class="whitespace-nowrap buttonprimary" @click="resendCourseEmail(selectedCourse)">
												Resend Course Email
											</button>
										</div>
										<div class="col-span-6 sm:col-span-4">
											<button
												type="button"
												v-if="!selectedCourse.expired"
												:disabled="submitted"
												class="whitespace-nowrap buttonprimary"
												@click="navigateCourse(selectedCourse.launchKey)"
											>
												Go To Course
											</button>
										</div>
										<div class="col-span-6 sm:col-span-4 text-right">
											<button type="button" class="buttonsecondary w-24 ml-4" @click="detailsOpen = false">Close</button>
										</div>
									</div>
								</div>
							</div>
						</TransitionChild>
					</div>
				</Dialog>
			</TransitionRoot>
		</div>
	</form>
</template>

<script>
import { parseISO, format } from 'date-fns';
import { Dialog, DialogOverlay, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue';
import TextInput from '../../components/controls/TextInput.vue';
import { useToast } from 'vue-toastification';

export default {
	name: 'AccountCourses',
	emits: ['activateCourse', 'resendCourseEmail'],
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionRoot,
		TransitionChild,
		TextInput,
	},
	props: {
		submitted: {
			type: Boolean,
			required: true,
		},
		courses: {
			type: Array,
			required: true,
		},
	},
	data: () => {
		return {
			courseHeaders: [
				{ name: 'Order', class: 'hidden xl:table-cell' },
				{ name: 'Payment Date', class: '' },
				{ name: 'Course Name', class: 'hidden sm:table-cell' },
				{ name: 'Email Address', class: 'hidden sm:table-cell' },
				{ name: 'Expiry Date', class: 'hidden lg:table-cell' },
				{ name: 'Status', class: '' },
			],
			selectedCourse: null,
			activationOpen: false,
			detailsOpen: false,
		};
	},
	methods: {
		getRowColor(i) {
			return i % 2 == 0 ? 'bg-white' : 'bg-gray-50';
		},
		getDateString(date) {
			if (date) {
				return format(parseISO(date), 'dd-MMM-yyyy');
			}
			return 'Never';
		},
		showCourseActivation(course) {
			this.selectedCourse = course;
			this.activationOpen = true;
		},
		activateCourse(course) {
			this.activationOpen = false;
			this.$emit('activateCourse', course);
		},
		showCourseDetails(course) {
			this.selectedCourse = course;
			this.detailsOpen = true;
		},
		navigateCourse(launchKey) {
			this.detailsOpen = false;

			useToast().success('Redirecting to course...');
			setTimeout(() => {
				this.$store.dispatch('loginClear');
				this.$router.push(`/startcourse?key=${launchKey}`);
			}, 2000);
		},
		resendCourseEmail(course) {
			this.detailsOpen = false;
			this.$emit('resendCourseEmail', course);
		},
	},
};
</script>
