<template>
	<div>
		<label :for="name" v-if="showlabel" class="formlabel">{{ label }}</label>
		<input
			:autocomplete="autocomplete"
			:autofocus="autofocus"
			:disabled="disabled"
			:form="form"
			:max="max"
			:maxlength="maxlength"
			:min="min"
			:minlength="minlength"
			:name="name"
			:pattern="pattern"
			:placeholder="placeholder"
			:readonly="readonly"
			:required="required"
			:type="type"
			:value="modelValue"
			@input="onUpdate($event)"
			@keydown="onKeyDown($event)"
			class="forminput"
		/>
	</div>
</template>

<script>
export default {
	name: 'TextInput',
	emits: ['update:modelValue'],
	props: {
		showlabel: {
			type: Boolean,
			default: true,
		},
		label: {
			type: String,
		},
		autocomplete: {
			type: String,
			default: 'off',
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		form: {
			type: String,
		},
		max: {
			type: [Number, String, Date],
		},
		maxlength: {
			type: Number,
		},
		min: {
			type: [Number, String, Date],
		},
		minlength: {
			type: Number,
		},
		name: {
			type: String,
			required: true,
		},
		pattern: {
			type: String,
		},
		placeholder: {
			type: String,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: true,
		},
		type: {
			type: String,
			required: true,
		},
		modelValue: {
			type: [Number, String, Date],
		},
	},
	methods: {
		onUpdate(event) {
			let value = event.target.value;

			if (value) {
				if (value.length > this.maxlength) {
					value = value.slice(0, this.maxlength);
				}
			} else {
				value = null;
			}

			this.$emit('update:modelValue', value);
		},
		onKeyDown(event) {
			if (this.type == 'number') {
				let keyCode = event.keyCode ? event.keyCode : event.which;
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
					event.preventDefault();
				}
			}
		},
	},
};
</script>
