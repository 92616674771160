<template>
	<form id="paymentInfo" @submit.prevent="submit">
		<div class="lg:py-24 py-16 px-5 space-y-6 lg:gap-x-5 bg-gradient-to-b from-gray-50 to-white">
			<div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 max-w-7xl mx-auto">
				<div class="grid grid-cols-3 md:gap-6">
					<div class="col-span-3 lg:col-span-1">
						<h3 class="text-lg font-medium leading-6 text-gray-900">Confirm Order Details</h3>
						<p class="mt-1 text-sm text-gray-500">Please confirm the details for your order.</p>
					</div>
					<div class="mt-5 md:mt-0 col-span-3 lg:col-span-2">
						<div class="grid grid-cols-6 gap-4">
							<div class="col-span-6 md:col-span-3">
								<TextInput
									label="First Name"
									autocomplete="on"
									:autofocus="true"
									form="paymentInfo"
									:maxlength="150"
									:minlength="1"
									name="firstName"
									placeholder="First Name"
									type="text"
									v-model="firstName"
									@update:model-value="firstName = $event"
								/>
							</div>
							<div class="col-span-6 md:col-span-3">
								<TextInput
									label="Last Name"
									autocomplete="on"
									form="paymentInfo"
									:maxlength="150"
									:minlength="1"
									name="lastName"
									placeholder="Last Name"
									type="text"
									v-model="lastName"
									@update:model-value="lastName = $event"
								/>
							</div>
							<div class="col-span-6 md:col-span-3">
								<TextInput
									label="Contact Number"
									autocomplete="on"
									form="paymentInfo"
									:maxlength="150"
									:minlength="1"
									name="contactNumber"
									placeholder="Contact Number"
									:required="false"
									type="tel"
									v-model="contactNumber"
									@update:model-value="contactNumber = $event"
								/>
							</div>
							<div class="col-span-6">
								<TextInput
									label="Address Line 1"
									autocomplete="on"
									form="paymentInfo"
									:maxlength="150"
									:minlength="1"
									name="AddressLine1"
									placeholder="Address Line 1"
									:required="false"
									type="text"
									v-model="addressLine1"
									@update:model-value="addressLine1 = $event"
								/>
							</div>
							<div class="col-span-6">
								<TextInput
									label="Address Line 2"
									autocomplete="on"
									form="paymentInfo"
									:maxlength="150"
									name="AddressLine2"
									placeholder="Address Line 2"
									:required="false"
									type="text"
									v-model="addressLine2"
									@update:model-value="addressLine2 = $event"
								/>
							</div>
							<div class="col-span-6">
								<TextInput
									label="Address Line 3"
									autocomplete="on"
									form="paymentInfo"
									:maxlength="150"
									name="AddressLine3"
									placeholder="Address Line 3"
									:required="false"
									type="text"
									v-model="addressLine3"
									@update:model-value="addressLine3 = $event"
								/>
							</div>
							<div class="col-span-6 md:col-span-3">
								<TextInput
									label="Post Code"
									autocomplete="on"
									form="paymentInfo"
									:maxlength="5"
									:minlength="1"
									name="PostCode"
									placeholder="Post Code"
									:required="false"
									type="text"
									v-model="postCode"
									@update:model-value="postCode = $event"
								/>
							</div>
							<div class="col-span-6 md:col-span-3">
								<MultiSelect
									controlLabel="Province"
									:hideSelected="false"
									id="provinceId"
									label="name"
									:multiple="false"
									:options="provinces"
									:showLabels="false"
									trackBy="provinceId"
									v-model="selectedProvince"
									@update:model-value="selectedProvince = $event"
								/>
							</div>
							<div class="col-span-6 font-bold flex gap-6">
								<div>Total Payment Amount:</div>
								<div>R {{ finalPaymentAmount }}</div>
							</div>
							<div class="px-4 py-3 text-right sm:px-6 col-span-6">
								<button @click="onCancel($event)" :disabled="submitted" class="buttonsecondary mr-3">Cancel</button>
								<button type="submit" :disabled="submitted" class="buttonprimary">Pay Now</button>
							</div>
							<div>
								<ErrorMessages :errorMessages="errorMessages" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import { mapGetters } from 'vuex';
import TextInput from '../../components/controls/TextInput.vue';
import MultiSelect from '../../components/controls/MultiSelect.vue';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';
import { webAccountService, storeService } from '../../services';
import { useToast } from 'vue-toastification';

export default {
	name: 'OrderCheckout',
	inject: ['appInsights'],
	components: {
		TextInput,
		MultiSelect,
		ErrorMessages,
	},
	data: () => {
		return {
			submitted: false,
			errorMessages: [],
			provinces: [],
			selectedProvince: {},
			firstName: null,
			lastName: null,
			contactNumber: null,
			addressLine1: null,
			addressLine2: null,
			addressLine3: null,
			postCode: null,
			deliveryChargeAmount: 0,
		};
	},
	computed: {
		...mapGetters(['webAccountId', 'emailAddress', 'cartItems', 'cartTotal']),
		finalPaymentAmount() {
			return parseFloat(this.cartTotal).toFixed(2);
		},
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.errorMessages = [];
			this.submitted = true;

			storeService
				.createPayFastOrder(
					this.webAccountId,
					this.selectedProvince ? this.selectedProvince.provinceId : null,
					this.firstName,
					this.lastName,
					this.contactNumber,
					this.addressLine1,
					this.addressLine2,
					this.addressLine3,
					this.postCode,
					this.deliveryChargeAmount
				)
				.then((response) => {
					if (response.data.result) {
						storeService
							.getPayFastPaymentUrl(
								this.webAccountId,
								response.data.data.orderId,
								this.firstName,
								this.lastName,
								this.emailAddress,
								`Order ${response.data.data.orderId}`,
								response.data.data.priceIncVatAndDeliveryChargeTotal.toFixed(2)
							)
							.then((response) => {
								this.submitted = false;

								if (response.data.result) {
									window.location.href = response.data.data;
								} else {
									useToast().error('Order checkout failed.');

									this.errorMessages[0] = response.data.message;
								}
							})
							.catch((error) => {
								this.submitted = false;

								useToast().error('Order checkout failed.');

								if (error.response && error.response.data && error.response.data.data) {
									this.errorMessages = error.response.data.data.slice();
								}

								this.appInsights.trackException({
									exception: new Error(`OrderCheckout getPayFastPaymentUrl:${error.message}`),
								});
							});
					} else {
						this.submitted = false;

						useToast().error('Order checkout failed.');

						this.errorMessages[0] = response.data.message;
					}
				})
				.catch((error) => {
					this.submitted = false;

					useToast().error('Order checkout failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`OrderCheckout createPayFastOrder:${error.message}`),
					});
				});
		},
		onCancel(event) {
			event.preventDefault();
			this.errorMessages = [];
			this.submitted = true;
			this.$router.push('/cart');
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'OrderCheckout' });

		const self = this;
		Promise.all([webAccountService.getWebAccount(this.webAccountId), storeService.getProvinces(), storeService.getCartItems(this.webAccountId)])
			.then((responses) => {
				responses.map((response, index) => {
					if (response.data.result) {
						switch (index) {
						case 0:
							this.selectedProvince = { provinceId: response.data.data.provinceId, name: response.data.data.province };
							this.firstName = response.data.data.firstName;
							this.lastName = response.data.data.lastName;
							this.contactNumber = response.data.data.contactNumber;
							this.addressLine1 = response.data.data.addressLine1;
							this.addressLine2 = response.data.data.addressLine2;
							this.addressLine3 = response.data.data.addressLine3;
							this.postCode = response.data.data.postCode;
							break;
						case 1:
							this.provinces = response.data.data;
							break;
						case 2:
							this.$store.dispatch('updateCartItems', {
								cartItems: response.data.data,
							});
							break;
						}
					} else {
						useToast().error('Error loading order. Redirecting to cart.');

						this.$router.push('/cart');
					}
				});
			})
			.catch((error) => {
				useToast().error('Error loading order. Redirecting to cart.');

				self.appInsights.trackException({
					exception: new Error(`OrderCheckout created:${error.message}`),
				});

				this.$router.push('/cart');
			});
	},
};
</script>
