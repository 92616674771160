<template>
	<form class="divide-y divide-gray-200 lg:col-span-9 py-4" id="accountOrders" @submit.prevent="submit">
		<div class="py-6 px-4 sm:p-6 lg:pb-8">
			<div>
				<h2 class="member-pageheader">Account Orders</h2>
			</div>
			<div class="bg-white px-4 py-5 sm:rounded-lg sm:p-3 max-w-7xl mx-auto">
				<div class="md:grid grid-cols-10 py-3">
					<div class="col-span-6">
						<h3 class="sm:text-lg font-medium leading-6 text-gray-900">Order History</h3>
						<p class="mt-1 text-sm text-gray-500">Details of your order history</p>
					</div>
					<div class="mt-5 md:mt-0 col-span-10 pb-6">
						<div class="flex flex-col">
							<div class="-my-2 sm:-mx-6 lg:-mx-8">
								<div class="py-2 align-middle inline-block lg:min-w-full sm:px-4 lg:px-8">
									<div class="border border-gray-200 mt-3 rounded shadow-sm overflow-hidden">
										<table class="lg:min-w-full divide-y divide-gray-200">
											<thead class="bg-gray-50">
												<tr>
													<th v-for="header in orderHeaders" :key="header.name" :class="header.class" scope="col" class="table-header">
														{{ header.name }}
													</th>
												</tr>
											</thead>
											<tbody>
												<tr :class="getRowColor(i)" v-for="(order, i) in orders" :key="i">
													<td class="table-item">
														<div class="flex items-center">
															<div class="mr-2">{{ `${getDateString(order.paymentDate)}` }}</div>
														</div>
													</td>
													<td class="table-item hidden sm:table-cell">
														<div class="items-center flex">
															<div><GiftIcon class="h-4 px-2 text-primary-700" /></div>
															<div>{{ order.paymentStatus }}</div>
														</div>
													</td>
													<td class="table-item">
														{{ order.paymentType }}
													</td>
													<td class="table-item">R {{ order.priceIncVatAndDeliveryChargeTotal.toFixed(2) }}</td>
													<td class="table-item hidden md:table-cell">{{ order.quantity }}</td>
													<td class="table-item">
														<div class="flex gap-1">
															<div
																v-if="order.paymentStatus === 'Completed'"
																:disabled="submitted"
																class="link-standard tracking-tight col-span-5"
																@click="getOrderInvoice(order.orderId)"
															>
																<DocumentDownloadIcon class="h-5 text-primary-900" />
															</div>
															<div class="link-standard tracking-tight col-span-5" @click="showOrderDetails(order.orderId)">Details</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>				
				<div class="bg-blue-900 text-white p-2 w-full text-sm font-medium rounded-md text-center">Please go to Courses menu item on the left hand side to activate and begin your course.</div>
			</div>
			<TransitionRoot as="template" :show="detailsOpen">
				<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="detailsOpen = false" :open="detailsOpen">
					<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<TransitionChild enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
							<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</TransitionChild>

						<!-- This element is to trick the browser into centering the modal contents. -->
						<span class="hidden" aria-hidden="true">&#8203;</span>
						<TransitionChild
							enter="ease-out duration-300"
							enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enter-to="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leave-from="opacity-100 translate-y-0 sm:scale-100"
							leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div
								class="
									inline-block
									align-bottom
									bg-white
									rounded-lg
									px-4
									mt-32
									pt-5
									pb-4
									text-left
									overflow-hidden
									shadow-xl
									transform
									transition-all
									sm:align-middle sm:max-w-3xl sm:w-full sm:p-6
								"
							>
								<div>
									<div class="text-center">
										<DialogTitle class="text-gray-900 px-2 text-sm">
											<div class="flex justify-between">
												<div class="w-2/3 flex items-center">
													<img :src="'images/deafinition_logo_pic.png'" class="pr-2" />
													<img :src="'images/deafinition_text_logo.png'" class="pr-2" />
												</div>
												<div class="text-left pr-12 table-item">
													<div>Deliver to:</div>
													<div>{{ selectedOrder.fullName }}</div>
													<div>{{ selectedOrder.addressLine1 }}</div>
													<div v-if="selectedOrder.addressLine2">{{ selectedOrder.addressLine2 }}</div>
													<div v-if="selectedOrder.addressLine3">{{ selectedOrder.addressLine3 }}</div>
													<div>{{ selectedOrder.province }}</div>
													<div>{{ selectedOrder.postCode }}</div>
												</div>
											</div>
											<div class="flex justify-between text-gray-600">
												<div class="text-gray-900 mt-5">Order ID: {{ selectedOrder.orderId }}</div>
												<div class="text-gray-900 mt-5">Date: {{ `${getDateString(selectedOrder.paymentDate)}` }}</div>
											</div>
										</DialogTitle>
										<div class="text-gray-900 mt-5 text-left text-sm font-semibold pl-2">Order Items</div>
										<div class="overflow-hidden border border-gray-200 sm:rounded-lg mt-2">
											<table class="min-w-full divide-y divide-gray-200 table-auto">
												<thead class="bg-gray-50">
													<tr>
														<th v-for="header in orderDetailHeaders" :key="header" scope="col" class="table-header">
															{{ header }}
														</th>
													</tr>
												</thead>
												<tbody>
													<tr :class="getRowColor(i)" v-for="(orderItem, i) in selectedOrder.orderItems" :key="i">
														<td class="table-item">
															<div class="flex items-center">
																<img :src="orderItem.imageUrl" class="mr-2 w-16" />
															</div>
														</td>
														<td class="table-item text-left">
															{{ orderItem.product }}
														</td>
														<td class="table-item">R {{ orderItem.priceIncVat.toFixed(2) }}</td>
														<td class="table-item">{{ orderItem.quantity }}</td>
														<td class="table-item">R {{ orderItem.priceIncVatTotal.toFixed(2) }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div class="mt-4 w-full text-right table-item space-y-1">
										<div class="flex justify-end">
											<div class="text-right pr-3">Sub-Total</div>
											<div>R {{ selectedOrder.priceExVatTotal.toFixed(2) }}</div>
										</div>
										<div class="flex justify-end">
											<div class="text-right pr-3">Delivery Charge</div>
											<div>R {{ selectedOrder.deliveryCharge.toFixed(2) }}</div>
										</div>
										<div class="flex justify-end">
											<div class="text-right pr-3">VAT</div>
											<div>R {{ selectedOrder.vatAmountTotal.toFixed(2) }}</div>
										</div>
										<div class="flex justify-end font-semibold">
											<div class="text-right pr-3">Order Total</div>
											<div>R {{ selectedOrder.priceIncVatAndDeliveryChargeTotal.toFixed(2) }}</div>
										</div>
									</div>
									<button type="button" class="buttonsecondary w-24 ml-4" @click="detailsOpen = false">Close</button>
								</div>
							</div>
						</TransitionChild>
					</div>
				</Dialog>
			</TransitionRoot>
		</div>
		
	</form>

</template>

<script>
import { parseISO, format } from 'date-fns';
import { GiftIcon, DocumentDownloadIcon } from '@heroicons/vue/outline';
import { Dialog, DialogOverlay, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue';

export default {
	name: 'AccountOrders',
	emits: ['getOrderInvoice'],
	components: {
		GiftIcon,
		DocumentDownloadIcon,
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionRoot,
		TransitionChild,
	},
	props: {
		submitted: {
			type: Boolean,
			required: true,
		},
		orders: {
			type: Array,
			required: true,
		},
	},
	data: () => {
		return {
			orderHeaders: [
				{ name: 'Date', class: '' },
				{ name: 'Status', class: 'hidden sm:table-cell' },
				{ name: 'Type', class: '' },
				{ name: 'Amount', class: '' },
				{ name: 'Quantity', class: 'hidden md:table-cell' },
				{ name: 'Download', class: '' },
			],
			orderDetailHeaders: ['Image', 'Description', 'Price', 'Quantity', 'Amount'],
			selectedOrder: null,
			detailsOpen: false,
		};
	},
	methods: {
		getRowColor(i) {
			return i % 2 == 0 ? 'bg-white' : 'bg-gray-50';
		},
		getDateString(date) {
			if (date) {
				return format(parseISO(date), 'dd-MMM-yyyy');
			}
			return '';
		},
		showOrderDetails(orderId) {
			let order = this.orders.find((m) => m.orderId === orderId);
			if (order) {
				this.selectedOrder = order;
				this.detailsOpen = true;
			}
		},
		getOrderInvoice(orderId) {
			this.$emit('getOrderInvoice', orderId);
		},
	},
};
</script>
