<template>
	<div class="relative bg-gray-100 border-b border-gray-200 py-16">
		<div class="absolute inset-0">
			<div class="absolute inset-y-0 left-0 w-1/2 bg-gray-100"></div>
		</div>
		<div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
			<div class="bg-gray-100 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
				<div class="max-w-lg mx-auto">
					<h2 class="pageheader">Get in touch</h2>
					<p class="mt-3 text-lg leading-6 text-gray-500">
						We'd love to hear from you. Please contact us using the details below or via the contact form on this page. Looking forward to connecting with you soon :)
					</p>
					<dl class="mt-8 text-base text-gray-500">
						<div class="mt-6">
							<dt class="sr-only">Phone number</dt>
							<dd class="flex">
								<PhoneIcon class="w-7 text-primary-800" />
								<span class="ml-3"> 087 063 8920 </span>
							</dd>
						</div>
						<div class="mt-3">
							<dt class="sr-only">Email</dt>
							<dd class="flex">
								<MailIcon class="w-7 text-primary-800" />
								<span class="ml-3"> info@deafinition.co.za </span>
							</dd>
						</div>
					</dl>					
				</div>
				<SocialMedia />
			</div>
			<div class="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12 shadow rounded-lg">
				<div class="max-w-lg mx-auto lg:max-w-none">
					<form id="contact" class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" @submit.prevent="submit">
						<div class="col-span-6 lg:col-span-3">
							<TextInput
								autocomplete="on"
								:autofocus="true"
								form="contact"
								:maxlength="150"
								:minlength="1"
								name="firstName"
								placeholder="First Name"
								type="text"
								v-model="firstName"
								@update:model-value="firstName = $event"
								class="col-span-6 sm:col-span-3"
							/>
						</div>
						<div class="col-span-6 lg:col-span-3">
							<TextInput
								autocomplete="on"
								form="contact"
								:maxlength="150"
								:minlength="1"
								name="lastName"
								placeholder="Last Name"
								type="text"
								v-model="lastName"
								@update:model-value="lastName = $event"
								class="col-span-6 sm:col-span-3"
							/>
						</div>
						<div class="col-span-6 lg:col-span-3">
							<TextInput
								autocomplete="on"
								form="contact"
								:maxlength="150"
								:minlength="5"
								name="emailAddress"
								placeholder="Email Address"
								type="email"
								v-model="emailAddress"
								@update:model-value="emailAddress = $event"
								class="col-span-6"
							/>
						</div>
						<div class="col-span-6 lg:col-span-3">
							<TextInput
								autocomplete="on"
								form="contact"
								:maxlength="150"
								:minlength="1"
								name="subject"
								placeholder="Subject"
								type="text"
								v-model="subject"
								@update:model-value="subject = $event"
								class="col-span-6 sm:col-span-3"
							/>
						</div>
						<div class="col-span-6 lg:col-span-3">
							<TextInput
								autocomplete="on"
								form="contact"
								:maxlength="8000"
								:minlength="1"
								name="message"
								placeholder="Message"
								type="text"
								v-model="message"
								@update:model-value="message = $event"
								class="col-span-6 sm:col-span-3"
							/>
						</div>
						<div>
							<button type="submit" :disabled="submitted" :class="{ 'opacity-50 cursor-not-allowed': submitted }" class="buttonprimary px-5 py-3">Submit</button>
						</div>
						<div>
							<ErrorMessages :errorMessages="errorMessages" />
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from '../../components/controls/TextInput.vue';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';
import SocialMedia from '../../components/SocialMedia.vue';
import { useToast } from 'vue-toastification';
import { webAccountService } from '../../services';
//icons
import { PhoneIcon, MailIcon } from '@heroicons/vue/outline';

export default {
	name: 'Contact',
	inject: ['appInsights'],
	components: {
		TextInput,
		ErrorMessages,
		PhoneIcon,
		MailIcon,
		SocialMedia,
	},
	data() {
		return {
			recapKey: process.env.VUE_APP_DEAFINITION_GOOGLE_RECAPTCHA_KEY,
			recapSecret: process.env.VUE_APP_DEAFINITION_GOOGLE_RECAPTCHA_SECRET,
			submitted: false,
			errorMessages: [],
			firstName: null,
			lastName: null,
			emailAddress: null,
			subject: null,
			message: null,
		};
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.errorMessages = [];
			this.submitted = true;

			webAccountService
				.sendEmail(this.firstName, this.lastName, this.emailAddress, `Contact Enquiry: ${this.subject}`, this.message)
				.then((response) => {
					this.submitted = false;

					if (response.data.result) {
						useToast().success('Message sent');

						this.$gtag.event('contact-submitted', { method: 'Google' });
					} else {
						useToast().error('Send message failed.');
						
						this.errorMessages[0] = response.data.message;
					}
				})
				.catch((error) => {
					this.submitted = false;

					useToast().error('Send message failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`Message send failed:${error.message}`),
					});
				});
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'Contact' });
		this.$gtag.event('contact', { method: 'Google' });
	},
};
</script>
