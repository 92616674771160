<template>
	<div class="w-full max-w-sm">
		<Popover v-slot="{ open }" class="relative">
			<PopoverButton
				:class="open ? '' : 'text-opacity-90'"
				class="
					inline-flex
					items-center
					my-2
					px-3
					py-3
					text-base
					font-medium
					text-gray-600
					bg-white
					rounded-md
					group
					hover:text-opacity-100
					focus:outline-none
					focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
				"
			>
				<span class="inline-flex items-center justify-center h-8 md:h-8 w-8 md:w-8 rounded-full bg-yellow-600 -mt-1">
					<UserIcon class="h-5 w-5 text-white" />
				</span>
				<span class="text-xs pl-2 hidden lg:block">{{ fullName }}</span>
				<span><ChevronDownIcon class="h-3 pl-1" /></span>
			</PopoverButton>
			<transition
				enter-active-class="transition duration-200 ease-out"
				enter-from-class="translate-y-1 opacity-0"
				enter-to-class="translate-y-0 opacity-100"
				leave-active-class="transition duration-150 ease-in"
				leave-from-class="translate-y-0 opacity-100"
				leave-to-class="translate-y-1 opacity-0"
			>
				<PopoverPanel class="absolute z-10 w-60 lg:w-64 max-w-96 px-4 transform -translate-x-1/3 left-1/3 sm:px-0">
					<div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
						<div class="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
							<a
								v-for="item in userMenuLinks"
								:key="item.name"
								:href="item.href"
								class="
									flex
									items-center
									p-2
									-m-3
									transition
									duration-150
									ease-in-out
									rounded-lg
									hover:bg-gray-50
									focus:outline-none
									focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-50
								"
							>
								<component :is="item.icon" class="text-gray-600 h-6 w-6"></component>
								<div class="ml-4">
									<p class="menu-item-header">
										{{ item.name }}
									</p>
									<p class="menu-item-text">
										{{ item.description }}
									</p>
								</div>
							</a>
						</div>
					</div>
				</PopoverPanel>
			</transition>
		</Popover>
	</div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { UserCircleIcon, CogIcon, GiftIcon, CreditCardIcon, LogoutIcon, UserIcon, ChevronDownIcon } from '@heroicons/vue/outline';
import site_data from '@/assets/data/site-data';

export default {
	name: 'UserMenu',
	components: {
		Popover,
		PopoverButton,
		PopoverPanel,
		UserCircleIcon,
		CogIcon,
		GiftIcon,
		CreditCardIcon,
		LogoutIcon,
		UserIcon,
		ChevronDownIcon,
	},
	props: {
		fullName: {
			type: String,
			default: null,
		},
	},
	data: () => {
		return {
			userMenuLinks: site_data.UserMenuLinks,
		};
	},
};
</script>
