<template>
	<div class="bg-gradient-to-b from-gray-50 to-white pb-10 py-24 px-4 lg:px-6 min-h-screen">
		<div class="max-w-7xl mx-auto lg:gap-x-5">
			<div class="flex justify-end py-4 items-center gap-6">
				<div>
					<button class="buttonsecondary" @click="onStoreClick()">Back to Store</button>
				</div>
				<div>
					<button v-if="loggedIn" class="buttonprimary" @click="onCartClick()">Go to Cart</button>
				</div>
			</div>
			<div class="grid grid-cols-6 max-w-7xl">
				<div class="col-span-6 object-contain">
					<StoreProductDetail v-if="product" :product="product" @addCartItem="onAddCartItem" />
				</div>
			</div>
			<div>
				<ErrorMessages :errorMessages="errorMessages" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import StoreProductDetail from '@/components/controls/StoreProductDetail.vue';
import { useToast } from 'vue-toastification';
import { storeService } from '../../services';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';

export default {
	name: 'ProductDetail',
	inject: ['appInsights'],
	components: {
		StoreProductDetail,
		ErrorMessages,
	},
	props: {
		productId: {
			type: Number,
			required: true,
		},
	},
	data: () => {
		return {
			errorMessages: [],
			product: null,
		};
	},
	computed: {
		...mapGetters(['loggedIn', 'webAccountId']),
	},
	methods: {
		getProduct(productId) {
			storeService.getProduct(productId).then(
				(response) => {
					if (response.data.result) {
						this.product = response.data.data;
					} else {
						useToast().error('Error loading product details.');
					}
				},
				(error) => {
					useToast().error('Error loading product details.');

					this.appInsights.trackException({
						exception: new Error(`getProduct:${error.message}`),
					});
				}
			);
		},
		onStoreClick() {
			this.$router.push('/store');
		},
		onCartClick() {
			this.$router.push('/cart');
		},
		onAddCartItem(productId, quantity) {
			this.errorMessages = [];

			storeService.saveCartItem(this.webAccountId, productId, quantity).then(
				(response) => {
					if (response.data.result) {
						useToast().success('Item added');

						this.$store.dispatch('updateCartItems', {
							cartItems: response.data.data,
						});
					} else {
						useToast().error('Add item failed.');

						this.errorMessages[0] = response.data.message;
					}
				},
				(error) => {
					useToast().error('Add item failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`onAddCartItem:${error.message}`),
					});
				}
			);
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'ProductDetail' });
		this.getProduct(this.productId);
	},
};
</script>
