<template>
	<div class="">
		<div class="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
			<div class="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
				<!-- Image gallery -->
				<TabGroup as="div" class="flex flex-col-reverse">
					<!-- Image selector -->
					<div class="hidden mt-6 w-full max-w-2xl mx-auto lg:max-w-none">
						<TabList class="grid grid-cols-4 gap-6">
							<Tab
								v-for="productImage in product.productImages"
								:key="productImage.productImageId"
								class="
									relative
									h-24
									bg-white
									rounded-md
									flex
									items-center
									justify-center
									text-sm
									font-medium
									uppercase
									text-gray-900
									cursor-pointer
									hover:bg-gray-50focus:outline-none
									focus:ring focus:ring-offset-4 focus:ring-opacity-50
								"
								v-slot="{ selected }"
							>
								<span class="sr-only">
									{{ productImage.name }}
								</span>
								<span class="absolute inset-0 rounded-md overflow-hidden">
									<img :src="productImage.sourceUrl" :alt="productImage.alternativeText" class="w-full h-full object-center object-cover" />
								</span>
								<span :class="[selected ? 'ring-primary-500' : 'ring-transparent', 'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none']" aria-hidden="true" />
							</Tab>
						</TabList>
					</div>
					<TabPanels class="w-full aspect-w-1 aspect-h-1">
						<TabPanel v-for="productImage in product.productImages" :key="productImage.productImageId">
							<img :src="productImage.sourceUrl" :alt="productImage.alternativeText" class="object-center object-contain sm:rounded-lg mt-6" />
						</TabPanel>
					</TabPanels>
				</TabGroup>
				<!-- Product info -->
				<div class="p-4 sm:px-0 lg:mt-0">
					<h1 class="text-2xl font-extrabold tracking-tight text-gray-900">
						{{ product.name }}
					</h1>
					<div class="mt-3">
						<h2 class="sr-only">Product information</h2>
						<p class="text-3xl text-gray-900">R{{ product.priceIncVat }}</p>
					</div>
					<div class="mt-6">
						<h3 class="sr-only">Description</h3>
						<div class="text-base text-gray-700 space-y-6" v-html="product.description" />
					</div>
					<div class="mt-10 flex sm:flex-col1">
						<button v-if="loggedIn" class="buttonprimary" @click="addToCart()">Add to Cart</button>
					</div>
					<section aria-labelledby="details-heading" class="mt-12">
						<h2 id="details-heading" class="sr-only">Additional details</h2>
						<div class="border-t divide-y divide-gray-200">
							<Disclosure as="div" v-for="productFeature in groupedProductFeatures" :key="productFeature.name" v-slot="{ open }">
								<h3>
									<DisclosureButton class="group relative w-full py-6 flex justify-between items-center text-left">
										<span :class="[open ? 'text-primary-600' : 'text-gray-900', 'text-sm font-medium']">
											{{ productFeature.name }}
										</span>
										<span class="ml-6 flex items-center">
											<PlusSmIcon v-if="!open" class="block h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
											<MinusSmIcon v-else class="block h-6 w-6 text-primary-400 group-hover:text-primary-500" aria-hidden="true" />
										</span>
									</DisclosureButton>
								</h3>
								<DisclosurePanel as="div" class="pb-6 prose prose-sm">
									<ul role="list">
										<li v-for="item in productFeature.items" :key="item">{{ item }}</li>
									</ul>
								</DisclosurePanel>
							</Disclosure>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Disclosure, DisclosureButton, DisclosurePanel, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { MinusSmIcon, PlusSmIcon } from '@heroicons/vue/outline';

export default {
	name: 'StoreProductDetail',
	emits: ['addCartItem'],
	components: {
		Disclosure,
		DisclosureButton,
		DisclosurePanel,
		Tab,
		TabGroup,
		TabList,
		TabPanel,
		TabPanels,
		MinusSmIcon,
		PlusSmIcon,
	},
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	data: () => {
		return {
			errorMessages: [],
		};
	},
	computed: {
		...mapGetters(['loggedIn']),
		groupedProductFeatures() {
			let groupedResult = [];
			if (this.product && this.product.productFeatures) {
				for (let i = 0; i < this.product.productFeatures.length; i++) {
					let index = groupedResult.findIndex((g) => g.name === this.product.productFeatures[i].group);
					if (index == -1) {
						groupedResult.push({
							name: this.product.productFeatures[i].group,
							items: [this.product.productFeatures[i].name],
						});
					} else {
						groupedResult[index].items.push(this.product.productFeatures[i].name);
					}
				}
			}
			return groupedResult;
		},
	},
	methods: {
		addToCart() {
			this.$emit('addCartItem', this.product.productId, 1);
		},
	},
};
</script>
