const webAccount = JSON.parse(localStorage.getItem('webAccount'));
const state = webAccount ? { loggedIn: true, webAccount } : { loggedIn: false, webAccount: null };

const mutations = {
	loginSuccess(state, webAccount) {
		state.loggedIn = true;
		state.webAccount = webAccount;
	},
	loginClear(state) {
		state.loggedIn = false;
		state.webAccount = null;
	},
	updateWebAccountFullName(state, webAccount) {
		state.webAccount = webAccount;
	},
};

const actions = {
	loginSuccess({ commit }, { webAccount }) {
		localStorage.setItem('webAccount', JSON.stringify(webAccount));
		commit('loginSuccess', webAccount);
	},
	loginClear({ commit }) {
		window.localStorage.clear();
		commit('loginClear');
	},
	updateWebAccountFullName({ commit }, { fullName }) {
		let webAccount = JSON.parse(localStorage.getItem('webAccount'));
		webAccount.fullName = fullName;
		localStorage.setItem('webAccount', JSON.stringify(webAccount));
		commit('updateWebAccountFullName', webAccount);
	},
};

const getters = {
	loggedIn: (state) => state.loggedIn,
	webAccountId: (state) => (state.loggedIn && state.webAccount ? state.webAccount.webAccountId : null),
	fullName: (state) => (state.loggedIn && state.webAccount ? state.webAccount.fullName : null),
	emailAddress: (state) => (state.loggedIn && state.webAccount ? state.webAccount.emailAddress : null),
	forcePasswordChangeOnNextLogin: (state) => (state.loggedIn && state.webAccount ? state.webAccount.forcePasswordChangeOnNextLogin : false),
};

export const webAccountModule = {
	state,
	mutations,
	actions,
	getters,
};

export default webAccountModule;
