import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import '@/assets/css/index.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtag from 'vue-gtag';

axios.interceptors.request.use(
	(config) => {
		config.headers.Accept = 'application/json';
		config.headers['Content-Type'] = 'application/json';
		config.headers['requestOriginKey'] = process.env.VUE_APP_DEAFINITION_REQUEST_ORIGIN_KEY;

		const webAccount = JSON.parse(localStorage.getItem('webAccount'));
		if (webAccount && webAccount.webToken) {
			config.headers.Authorization = `Bearer ${webAccount.webToken}`;
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 401) {
			store.dispatch('loginClear');
			router.push('/');
		}
		return Promise.reject(error);
	}
);

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
	config: {
		connectionString: process.env.VUE_APP_DEAFINITION_APPLICATION_INSIGHTS_CONNECTION_STRING,
	},
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const toastOptions = {
	position: 'top-center',
	timeout: 1500, // milliseconds
};

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(Toast, toastOptions);
app.provide('appInsights', appInsights);
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_DEAFINITION_GOOGLE_RECAPTCHA_KEY });
app.use(VueGtag, {
	config: { id: 'G-GH4GB21D93' },
});
app.mount('#app');
