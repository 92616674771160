<template>
	<div class="grid grid-cols-8 items-center w-full justify-between">
		<div class="col-span-2 row-span-3 lg:row-span-1 lg:col-span-1 mx-auto">
			<img class="h-12 sm:h-16" :src="cartItem.defaultImageSourceUrl" :alt="cartItem.defaultImageAlternativeText" />
		</div>
		<div class="text-xs lg:text-sm font-semibold col-span-5 lg:col-span-3 lg:ml-2 px-4">
			<div>{{ cartItem.product }}</div>
			<div class="text-xs tracking-tight font-light">{{ cartItem.description }}</div>
		</div>
		<div class="col-span-1 row-span-3 mx-auto align-text-top block lg:hidden">
			<TrashIcon :class="cartItem.productId == 0 ? 'text-gray-300 h-5 cursor-not-allowed' : 'h-5 text-red-600'" @click="removeCartItem(cartItem.productId, 0)" />
		</div>
		<div class="col-span-3 lg:col-span-1 text-xs lg:text-sm text-left lg:text-right flex">
			<div>R {{ unitPrice }}</div>
			<div class="ml-1">each</div>
		</div>
		<div class="flex items-center lg:justify-end space-x-1 col-span-2 lg:col-span-1">
			<PlusIcon
				v-if="cartItem.quantity < maximumQuantity"
				:class="cartItem.productId == 0 ? 'text-gray-300 h-4 cursor-not-allowed' : 'h-4 w-4 cursor-pointer'"
				@click="addToCart(cartItem.productId)"
			/>
			<div class="text-xs lg:text-sm font-semibold border border-gray-200 rounded-lg px-3 py-2 mt-1">{{ cartItem.quantity }}</div>
			<MinusIcon
				:class="cartItem.productId == 0 ? 'text-gray-300 h-4 cursor-not-allowed' : 'h-4 w-4 cursor-pointer'"
				@click="removeCartItem(cartItem.productId, cartItem.quantity - 1)"
			/>
		</div>
		<div class="text-xs lg:text-sm col-span-5 lg:col-span-1 font-semibold text-left lg:justify-end flex">
			<div class="block lg:hidden mr-2">Total:</div>
			<div>R {{ cartItem.priceIncVat }}</div>
		</div>
		<div class="hidden lg:block col-span-1 row-span-3 mx-auto align-text-top cursor-pointer">
			<TrashIcon :class="cartItem.productId == 0 ? 'text-gray-300 h-5 cursor-not-allowed' : 'h-5 text-red-600'" @click="removeCartItem(cartItem.productId, 0)" />
		</div>
	</div>
</template>
<script>
import { TrashIcon, PlusIcon, MinusIcon } from '@heroicons/vue/outline';

export default {
	name: 'CartItem',
	emits: ['addCartItem', 'removeCartItem'],
	components: {
		PlusIcon,
		MinusIcon,
		TrashIcon,
	},
	props: {
		cartItem: {
			type: Object,
			required: true,
		},
		maximumQuantity: {
			type: Number,
			required: true,
		},
	},
	computed: {
		unitPrice() {
			if (this.cartItem && this.cartItem.quantity > 0 && this.cartItem.priceIncVat > 0) {
				return (this.cartItem.priceIncVat / this.cartItem.quantity).toFixed(2);
			}
			return 0;
		},
	},
	methods: {
		addToCart(productId) {
			this.$emit('addCartItem', productId, 1);
		},
		removeCartItem(productId, quantity) {
			this.$emit('removeCartItem', productId, quantity);
		},
	},
};
</script>
