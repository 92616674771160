<template>
	<div class="min-h-screen flex items-center justify-center py-28 px-4 sm:px-6 lg:px-8 overflow-hidden">
		<div class="max-w-md w-full space-y-8">
			<div>
				<div class="mx-auto hidden md:block">
					<a href="/"><img class="object-cover h-12" alt="Deafinition logo" src="images/deafinition_text_logo.png"/></a>
				</div>
				<h2 class="mt-4 text-xl lg:text-3xl font-extrabold text-gray-900">
					Reset Your Password
				</h2>
				<div class="mt-1 text-sm text-gray-600">A password reset email will be sent to your email address.</div>
				<div class="max-w-md w-full space-y-6">
					<form id="forgotPassword" class="mt-8 space-y-4" @submit.prevent="submit">
						<TextInput
							autocomplete="on"
							:autofocus="true"
							form="forgotPassword"
							:maxlength="150"
							:minlength="5"
							name="emailAddress"
							placeholder="Email Address"
							type="email"
							v-model="emailAddress"
							@update:model-value="emailAddress = $event"
						/>
						<div class="py-1 text-right">
							<button type="submit" :disabled="submitted" class="buttonprimary py-3">Reset Password</button>
						</div>
						<div>
							<ErrorMessages :errorMessages="errorMessages" />
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from '../../components/controls/TextInput.vue';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';
import { webAccountService } from '../../services';
import { useToast } from 'vue-toastification';

export default {
	name: 'ForgotPassword',
	inject: ['appInsights'],
	components: {
		TextInput,
		ErrorMessages,
	},
	data: () => {
		return {
			submitted: false,
			errorMessages: [],
			emailAddress: null,
		};
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.errorMessages = [];
			this.submitted = true;
			
			webAccountService
				.forgotPassword(this.emailAddress)
				.then((response) => {
					this.submitted = false;

					if (response.data.result) {
						useToast().success('Password reset email sent. Redirecting to login.');

						this.$router.push('/login');
					} else {					
						useToast().error('Password reset failed.');
							
						this.errorMessages[0] = response.data.message;
					}
				})
				.catch((error) => {
					this.submitted = false;

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}
					
					this.appInsights.trackException({
						exception: new Error(`Password reset failed:${error.message}`),
					});
				});
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'ForgotPassword' });
		this.$store.dispatch('loginClear');
	},
};
</script>
