<template>
	<div class="min-h-screen flex items-center justify-center py-28 px-4 sm:px-6 lg:px-8 overflow-hidden">
		<div class="max-w-md w-full space-y-8">
			<div>
				<div class="mx-auto hidden md:block">
					<a href="/"><img class="object-cover h-12" alt="Deafinition logo" src="images/deafinition_text_logo.png" /></a>
				</div>
				<h2 class="mt-4 text-2xl lg:text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
				<p class="mt-2 text-base text-gray-600">
					Or
					<a href="/register" class="font-medium text-blue-600 hover:text-blue-500"> register for an account </a>
				</p>
			</div>
			<form id="login" class="mt-8 space-y-6" @submit.prevent="submit">
				<div class="rounded-md shadow-sm space-y-4">
					<TextInput
						autocomplete="on"
						:autofocus="true"
						form="login"
						:maxlength="150"
						:minlength="5"
						name="emailAddress"
						placeholder="User name or email address"
						type="text"
						v-model="emailAddress"
						@update:model-value="emailAddress = $event"
					/>
					<TextInput
						form="login"
						:maxlength="15"
						:minlength="5"
						name="password"
						placeholder="Password"
						type="password"
						v-model="password"
						@update:model-value="password = $event"
					/>
				</div>
				<div>
					<button type="submit" :disabled="submitted" class="buttonprimary px-5 py-3">Sign in</button>
				</div>
				<div class="grid grid-cols-6 items-center justify-between text-sm">
					<div @click="forgotPassword" class="link-standard col-span-6 sm:col-span-3 mt-2 tracking-tight">Forgot your password?</div>
					<div @click="resendConfirmRegistrationEmail" class="link-standard col-span-6 sm:col-span-3 mt-2 tracking-tight">Resend Confirm Registration Email</div>
				</div>
				<div>
					<ErrorMessages :errorMessages="errorMessages" />
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextInput from '../../components/controls/TextInput.vue';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';
import { useToast } from 'vue-toastification';
import { webAccountService } from '../../services';

export default {
	name: 'LogIn',
	inject: ['appInsights'],
	components: {
		TextInput,
		ErrorMessages,
	},
	data() {
		return {
			submitted: false,
			errorMessages: [],
			emailAddress: null,
			password: null,
		};
	},
	computed: {
		...mapGetters(['loggedIn']),
	},
	watch: {
		loggedIn: {
			immediate: true,
			deep: false,
			handler(loggedIn) {
				if (loggedIn) {
					this.$router.push('/account');
				}
			},
		},
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.errorMessages = [];
			this.submitted = true;

			webAccountService
				.authenticateWebAccount(this.emailAddress, this.password)
				.then((response) => {
					this.submitted = false;

					if (response.data.result && response.data.data && response.data.data.webToken) {
						this.$store.dispatch('loginSuccess', {
							webAccount: response.data.data,
						});
					} else {
						useToast().error('Login failed.');

						this.errorMessages[0] = response.data.message;
					}
				})
				.catch((error) => {
					this.submitted = false;

					useToast().error('Login failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`Login failed:${error.message}`),
					});
				});
		},
		forgotPassword() {
			this.errorMessages = [];
			this.submitted = true;
			this.$router.push('/forgotPassword');
		},
		resendConfirmRegistrationEmail() {
			this.errorMessages = [];
			this.submitted = true;
			this.$router.push('/resendconfirmregistrationemail');
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'Login' });
		this.$store.dispatch('loginClear');
	},
};
</script>
