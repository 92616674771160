<template>
	<div>
		<h2 class="mt-4 text-2xl lg:text-3xl font-extrabold text-gray-900">Reset Password</h2>
	</div>
</template>

<script>
import { webAccountService } from '../../services';
import { useToast } from 'vue-toastification';

export default {
	name: 'ResetWebAccountPassword',
	inject: ['appInsights'],
	methods: {
		resetWebAccountPassword(key) {
			webAccountService
				.resetWebAccountPassword(key)
				.then((response) => {
					if (response.data.result) {
						useToast().success('Reset password completed. Redirecting to login.');

						this.$router.push('/login');
					} else {
						useToast().error('Reset password failed. Redirecting to login.');

						this.$router.push('/login');
					}
				})
				.catch((error) => {
					useToast().error('Reset password failed. Redirecting to login.');

					this.appInsights.trackException({
						exception: new Error(`resetWebAccountPassword:${error.message}`),
					});

					this.$router.push('/login');
				});
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'ResetWebAccountPassword' });

		const parameters = this.$route.query;
		if (parameters.key) {
			this.resetWebAccountPassword(parameters.key);
		} else {
			useToast().error('Reset password failed.');

			this.$router.push('/login');
		}
	},
};
</script>
