<template>
	<div class="">
		<div class="max-w-2xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
			<h2 class="sr-only">Products</h2>
			<div class="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-2 lg:gap-x-8">
				<div class="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden">
					<div class="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96">
						<img :src="product.defaultImageSourceUrl" :alt="product.defaultImageAlternativeText" class="w-full h-full object-center object-cover sm:w-full sm:h-full" />
					</div>
					<div class="flex-1 p-4 space-y-2 flex flex-col">
						<h3 class="text-sm font-medium text-gray-900">
							{{ product.product }}
						</h3>
						<p class="text-sm text-gray-500">
							{{ product.description }}
						</p>
						<div class="flex-1 flex flex-col justify-end">
							<p class="text-base font-medium text-gray-900">R{{ product.priceIncVat }}</p>
							<div class="flex w-full gap-2 justify-around">
								<button class="my-3 buttonsecondary w-1/2" @click="productDetails()">Details</button>
								<button v-if="loggedIn" class="my-3 buttonprimary w-1/2" @click="addToCart()">Add to Cart</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'StoreProduct',
	emits: ['productDetails', 'addCartItem'],
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapGetters(['loggedIn']),
	},
	methods: {
		productDetails() {
			this.$emit('productDetails', this.product.productId);
		},
		addToCart() {
			this.$emit('addCartItem', this.product.productId, 1);
		},
	},
};
</script>
