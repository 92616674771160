<template>
	<footer class="" aria-labelledby="footerHeading">
		<div class="mx-auto px-4 sm:px-6  lg:px-24">		
			<div class="pt-2">
				<p class="text-sm font-medium text-gray-400 xl:text-center">"If you talk to a man in a language he understands, that goes to his head.  If you talk to him in his language, that goes to his heart” – Nelson Mandela</p>
			</div>
		</div>
		<div class="mx-auto py-4 px-4 sm:px-6 lg:py-2 lg:px-24">		
			<div class="border-t border-gray-200 pt-4">
				<p class="text-xs text-gray-400 xl:text-center">&copy; 2024 <a class="text-primary-700" href="https://www.cogni.co.za">Cognizance</a> All rights reserved.</p>
			</div>
		</div>
	</footer>
</template>

<script>
import site_data from '@/assets/data/site-data';
export default {
	components: {
	},
	data: () => {
		return {
			services: site_data.Services,
			resources: site_data.Resources,
			team: site_data.Team,
			contact: site_data.Contact,
		};
	},
	methods: {
		linkClick(name, route) {
			this.$gtag.event('footer-' + name, { method: 'Google' });			
			this.$router.push(route);
		},
	}
}
</script>
