<template>
	<div :class="inputStyle ? 'displaystyle-input' : 'displaystyle-text'">
		<div v-if="showlabel" :class="inputStyle ? 'formlabel' : 'displayheader'">
			{{ label }}
		</div>
		<div :class="[inputStyle ? 'forminput bg-gray-50 border border-primary-500' : 'displaytext', !modelValue ? 'h-10' : '']">
			{{ `${prefix}${modelValue || ''}${suffix}` }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'DisplayField',
	props: {
		showlabel: {
			type: Boolean,
			default: true,
		},
		label: {
			type: String,
		},
		modelValue: {
			type: [Number, String],
			default: null,
		},
		inputStyle: {
			type: Boolean,
			default: false,
		},
		prefix: {
			type: String,
			default: '',
		},
		suffix: {
			type: String,
			default: '',
		},
	},
};
</script>
