<template>
	<div class="min-h-screen flex items-center justify-center py-28 px-4 sm:px-6 lg:px-8 overflow-hidden">
		<div class="max-w-lg w-full space-y-4">
			<div>
				<div class="mx-auto hidden md:block">
					<a href="/"><img class="object-cover h-12" alt="deafinition logo" src="images/deafinition_text_logo.png" /></a>
				</div>
				<h2 class="mt-4 text-2xl lg:text-3xl font-extrabold text-gray-900">Register for an account</h2>
				<p class="mt-2 text-base text-gray-600">
					Or
					<a href="/login" class="link-standard font-medium"> login to an existing account </a>
				</p>
			</div>
			<form id="register" class="mt-8 space-y-6" @submit.prevent="submit">
				<div class="rounded-md shadow-sm grid grid-cols-6 gap-3">
					<TextInput
						autocomplete="on"
						form="register"
						:maxlength="150"
						:minlength="1"
						name="firstName"
						placeholder="First Name"
						type="text"
						v-model="firstName"
						@update:model-value="firstName = $event"
						class="col-span-6 sm:col-span-3"
					/>
					<TextInput
						autocomplete="on"
						form="register"
						:maxlength="150"
						:minlength="1"
						name="lastName"
						placeholder="Last Name"
						type="text"
						v-model="lastName"
						@update:model-value="lastName = $event"
						class="col-span-6 sm:col-span-3 sm:pl-2"
					/>
					<TextInput
						autocomplete="on"
						form="register"
						:maxlength="150"
						:minlength="5"
						name="emailAddress"
						placeholder="Email Address"
						type="email"
						v-model="emailAddress"
						@update:model-value="emailAddress = $event"
						class="col-span-6"
					/>
					<TextInput
						form="register"
						:maxlength="15"
						:minlength="5"
						name="password"
						placeholder="Password"
						type="password"
						v-model="password"
						@update:model-value="password = $event"
						class="col-span-6 sm:col-span-3"
					/>
					<TextInput
						form="register"
						:maxlength="15"
						:minlength="5"
						name="confirmPassword"
						placeholder="Confirm password"
						type="password"
						v-model="confirmPassword"
						@update:model-value="confirmPassword = $event"
						class="col-span-6 sm:col-span-3 sm:pl-2"
					/>
				</div>
				<div class="text-xs text-gray-400">Password must be between 5 and 15 characters, with a minimum of 1 upper case, 1 lower case and 1 digit (0-9).</div>
				<div class="text-sm pt-2 text-gray-600">
					Before completing your registration, please read our
					<a @click="linkClick('privacypolicy', '/privacypolicy')" target="blank" class="link-standard"> privacy policy. </a>
				</div>
				<div>
					<div class="items-center flex">
						<div>
							<Switch
								v-model="PopiConsent"
								:class="[
									PopiConsent ? 'bg-primary-600' : 'bg-gray-200',
									'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
								]"
							>
								<span class="sr-only">Use setting</span>
								<span
									:class="[
										PopiConsent ? 'translate-x-5' : 'translate-x-0',
										'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
									]"
								>
									<span
										:class="[
											PopiConsent ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
											'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
										]"
										aria-hidden="true"
									>
										<svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
											<path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</span>
									<span
										:class="[
											PopiConsent ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
											'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
										]"
										aria-hidden="true"
									>
										<svg class="h-3 w-3 text-primary-600" fill="currentColor" viewBox="0 0 12 12">
											<path
												d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
											/>
										</svg>
									</span>
								</span>
							</Switch>
						</div>
						<div class="text-sm font-semibold ml-5 -mt-1">I accept</div>
					</div>
					<div class="text-sm pt-4 text-gray-600">
						By clicking
						<b>I accept</b> above, you acknowledge that you have read and accept the Disclaimer document.
					</div>
				</div>
				<div>
					<button type="submit" :disabled="!PopiConsent || submitted" :class="{ 'opacity-50 cursor-not-allowed': !PopiConsent || submitted }" class="buttonprimary px-5 py-3">
						Register
					</button>
				</div>
				<div>
					<ErrorMessages :errorMessages="errorMessages" />
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextInput from '../../components/controls/TextInput.vue';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';
import { useToast } from 'vue-toastification';
import { webAccountService } from '../../services';
import { Switch } from '@headlessui/vue';

export default {
	name: 'Register',
	inject: ['appInsights'],
	components: {
		TextInput,
		ErrorMessages,
		Switch,
	},
	data() {
		return {
			submitted: false,
			PopiConsent: false,
			errorMessages: [],
			emailAddress: null,
			password: null,
			confirmPassword: null,
			firstName: null,
			lastName: null,
			companyAccount: false,
			companyName: null,
		};
	},
	computed: {
		...mapGetters(['loggedIn']),
	},
	watch: {
		loggedIn: {
			immediate: true,
			deep: false,
			handler(loggedIn) {
				if (loggedIn) {
					this.$router.push('/account');
				}
			},
		},
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.errorMessages = [];
			this.submitted = true;

			webAccountService
				.registerWebAccount(this.emailAddress, this.password, this.confirmPassword, this.firstName, this.lastName, this.companyAccount, this.companyName)
				.then((response) => {
					this.submitted = false;

					if (response.data.result) {
						this.$router.push('/confirmregistration');
					} else {
						useToast().error('Registration failed.');

						this.errorMessages[0] = response.data.message;
					}
				})
				.catch((error) => {
					this.submitted = false;

					useToast().error('Registration failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`Registration failed:${error.message}`),
					});
				});
		},
		linkClick(name, route) {
			this.$gtag.event('footer-' + name, { method: 'Google' });
			this.$router.push(route);
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'Register' });
	},
};
</script>
