<template>
	<div class="min-h-screen flex items-center justify-center py-28 px-4 sm:px-6 lg:px-8 overflow-hidden">
		<div class="max-w-md w-full space-y-8">
			<div>
				<div class="mx-auto hidden md:block">
					<a href="/"><img class="object-cover h-16" alt="SASLOnline logo" src="images/saslonline_text_logo.png"/></a>
				</div>
				<h2 class="mt-4 text-2xl lg:text-3xl font-extrabold text-gray-900">
					Thanks for Registering
				</h2>
				<div class="mt-3 text-gray-600">
					Within the next few minutes, you will receive an email to confirm your registration. Once you have clicked on the Confirm Registration link in the email, you will be able
					to log in.
				</div>
				<div class="mt-6 text-gray-600">
					If you don't receive your registration email within 30 minutes, please check your spam/junk mail folder or click on the link below to resend your confirmation email.
				</div>
				<div @click="$router.push('/resendconfirmregistrationemail')" class="mt-4 text-base col-span-6 sm:col-span-3 font-medium link-standard">
					Resend Confirm Registration Email
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ConfirmRegistration',
	created() {
		this.$store.dispatch('loginClear');
	},
};
</script>
