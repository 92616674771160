<template>
	<div>
		<h2 class="mt-4 text-2xl lg:text-3xl font-extrabold text-gray-900">Cancel Payment</h2>
	</div>
</template>

<script>
import { storeService } from '../../services';
import { useToast } from 'vue-toastification';

export default {
	name: 'PaymentCanceled',
	inject: ['appInsights'],
	methods: {
		cancelOrderPayment(orderId) {
			storeService
				.cancelOrderPayment(orderId)
				.then((response) => {
					if (response.data.result) {
						useToast().success('Payment cancelled. Redirecting to cart.');

						this.$router.push('/cart');
					} else {
						useToast().error('Cancel payment failed. Redirecting to cart.');

						this.$router.push('/cart');
					}
				})
				.catch((error) => {
					useToast().error('Cancel payment failed. Redirecting to cart.');

					this.appInsights.trackException({
						exception: new Error(`cancelOrderPayment:${error.message}`),
					});

					this.$router.push('/cart');
				});
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'PaymentCanceled' });

		const parameters = this.$route.query;
		if (parameters.orderId) {
			const orderId = parseInt(parameters.orderId);
			if (!isNaN(orderId)) {
				this.cancelOrderPayment(orderId);
			} else {
				useToast().error('Cancel payment failed. Redirecting to cart.');

				this.$router.push('/cart');
			}
		} else {
			useToast().error('Cancel payment failed. Redirecting to cart.');

			this.$router.push('/cart');
		}
	},
};
</script>
