<template>
	<div class="py-24">
		<div v-if="errorMessages.length === 0">Redirecting to course...</div>
		<div v-if="errorMessages.length > 0">
			<ErrorMessages :errorMessages="errorMessages" />
		</div>
	</div>
</template>

<script>
import ErrorMessages from '../../components/controls/ErrorMessages.vue';
import { useToast } from 'vue-toastification';
import { storeService } from '../../services';

export default {
	name: 'StartCourse',
	inject: ['appInsights'],
	components: {
		ErrorMessages,
	},
	data() {
		return {
			errorMessages: [],
			key: null,
		};
	},
	methods: {
		getOrderItemDigitalProductByLaunchKey(launchKey) {
			storeService.getOrderItemDigitalProductByLaunchKey(launchKey).then(
				(response) => {
					if (response.data.result) {
						window.location.href = response.data.data;
					}
				},
				(error) => {
					this.appInsights.trackException({
						exception: new Error(`getOrderItemDigitalProducts:${error.message}`),
					});
				}
			);
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'StartCourse' });

		const parameters = this.$route.query;
		if (parameters.key) {
			this.getOrderItemDigitalProductByLaunchKey(parameters.key);
		} else {
			useToast().error('Start course failed.');

			this.$router.push('/');
		}
	},
};
</script>
