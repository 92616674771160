<template>
	<div>
		<label :for="id" v-if="showlabel" class="formlabel">{{ controlLabel }}</label>
		<multiselect
			class="mt-1 shadow-sm text-sm"
			:allowEmpty="allowEmpty"
			:clearOnSelect="clearOnSelect"
			:closeOnSelect="closeOnSelect"
			:deselectLabel="deselectLabel"
			:disabled="disabled"
			:hideSelected="hideSelected"
			:id="id"
			:label="label"
			:limit="limit"
			:loading="loading"
			:max="max"
			:maxHeight="maxHeight"
			:multiple="multiple"
			:openDirection="openDirection"
			:options="options"
			:optionsLimit="optionsLimit"
			:placeholder="placeholder"
			:preselectFirst="preselectFirst"
			:preserveSearch="preserveSearch"
			:searchable="searchable"
			:selectLabel="selectLabel"
			:selectedLabel="selectedLabel"
			:showLabels="showLabels"
			:showNoOptions="showNoOptions"
			:showNoResults="showNoResults"
			:tabIndex="tabIndex"
			:trackBy="trackBy"
			v-model="currentValue"
			@update:modelValue="$emit('update:modelValue', currentValue)"
		>
		</multiselect>
	</div>
</template>

<script>
import Multiselect from '@suadelabs/vue3-multiselect';

export default {
	name: 'MultiSelect',
	emits: ['update:modelValue'],
	components: {
		Multiselect,
	},
	props: {
		showlabel: {
			type: Boolean,
			default: true,
		},
		controlLabel: {
			type: String,
			required: true,
		},
		allowEmpty: {
			type: Boolean,
			default: true,
		},
		clearOnSelect: {
			type: Boolean,
			default: true,
		},
		closeOnSelect: {
			type: Boolean,
			default: true,
		},
		deselectLabel: {
			type: String,
			default: 'Press enter to remove',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		hideSelected: {
			type: Boolean,
			default: true,
		},
		id: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		limit: {
			type: Number,
			default: 99999,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		max: {
			type: Number,
			default: 0,
		},
		maxHeight: {
			type: Number,
			default: 300,
		},
		multiple: {
			type: Boolean,
			default: true,
		},
		openDirection: {
			type: String,
			default: 'below',
		},
		options: {
			type: Array,
			required: true,
		},
		optionsLimit: {
			type: Number,
			default: 1000,
		},
		placeholder: {
			type: String,
			default: 'Type to filter options',
		},
		preselectFirst: {
			type: Boolean,
			default: false,
		},
		preserveSearch: {
			type: Boolean,
			default: false,
		},
		searchable: {
			type: Boolean,
			default: true,
		},
		selectLabel: {
			type: String,
			default: 'Press enter to select',
		},
		selectedLabel: {
			type: String,
			default: 'Selected',
		},
		showLabels: {
			type: Boolean,
			default: true,
		},
		showNoOptions: {
			type: Boolean,
			default: true,
		},
		showNoResults: {
			type: Boolean,
			default: true,
		},
		tabIndex: {
			type: Number,
			default: 0,
		},
		trackBy: {
			type: String,
			required: true,
		},
		modelValue: {
			type: null,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			currentValue: this.modelValue,
		};
	},
	watch: {
		modelValue: {
			handler(modelValue) {
				this.currentValue = modelValue;
			},
			immediate: true,
		},
	},
};
</script>

<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css" />
