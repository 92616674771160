<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="bg-primary-900 pt-10">
		<div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
			<div class="flex items-center justify-between flex-wrap">
				<div class="w-0 flex-1 flex items-center justify-center gap-8">
					<div class="ml-3 font-medium text-white truncate flex items-center text-lg">
						<span class="md:hidden"> Register for our Sign Language Course </span>
						<span class="hidden sm:tracking-tight md:inline"> Register for our Sign Language Course.</span>
					</div>
					<div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
						<a href="/register" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-600 bg-white hover:bg-primary-50">
							Register for an Account
						</a>
					</div>
				</div>
				<div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
					<button type="button" class="-mr-1 flex p-2 rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2">
						<span class="sr-only">Dismiss</span>
						<XIcon class="h-6 w-6 text-white hidden" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline';

export default {
	components: {
		XIcon,
	},
};
</script>
