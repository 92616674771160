<template>
	<HeaderBanner />
	<Hero />	
</template>

<script>
import HeaderBanner from '../components/HeaderBanner.vue';
import Hero from '../components/Hero.vue';

export default {
	name: 'Home',
	components: { Hero, HeaderBanner},
};
</script>
