import axios from 'axios';

function getCartItems(webAccountId) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/GetCartItems`;
	const params = { webAccountId };

	return axios({
		method: 'get',
		url,
		params,
	});
}

function getProvinces() {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/GetProvinces`;

	return axios({
		method: 'get',
		url,
	});
}

function getProducts() {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/GetProducts`;

	return axios({
		method: 'get',
		url,
	});
}

function getProduct(productId) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/GetProduct`;
	const params = { productId };

	return axios({
		method: 'get',
		url,
		params,
	});
}

function saveCartItem(webAccountId, productId, quantity) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/SaveCartItem`;
	const data = JSON.stringify({ webAccountId, productId, quantity });

	return axios({
		method: 'post',
		url,
		data,
	});
}

function removeCartItem(webAccountId, productId) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/RemoveCartItem`;
	const data = JSON.stringify({ webAccountId, productId });

	return axios({
		method: 'delete',
		url,
		data,
	});
}

function clearCart(webAccountId) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/ClearCart`;
	const data = JSON.stringify({ webAccountId });

	return axios({
		method: 'delete',
		url,
		data,
	});
}

function createPayFastOrder(webAccountId, provinceId, firstName, lastName, contactNumber, addressLine1, addressLine2, addressLine3, postCode, deliveryCharge) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/CreatePayFastOrder`;
	const data = JSON.stringify({
		webAccountId,
		provinceId,
		firstName,
		lastName,
		contactNumber,
		addressLine1,
		addressLine2,
		addressLine3,
		postCode,
		deliveryCharge,
	});

	return axios({
		method: 'post',
		url,
		data,
	});
}

function getPayFastPaymentUrl(webAccountId, paymentId, firstName, lastName, emailAddress, itemName, amount) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/GetPayFastPaymentUrl`;

	const data = JSON.stringify({
		webAccountId,
		paymentId,
		firstName,
		lastName,
		emailAddress,
		itemName,
		amount,
	});

	return axios({
		method: 'post',
		url,
		data,
	});
}

function getOrders(webAccountId) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/GetOrders`;
	const params = { webAccountId };

	return axios({
		method: 'get',
		url,
		params,
	});
}

function cancelOrderPayment(orderId) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/CancelOrderPayment`;
	const data = JSON.stringify(orderId);

	return axios({
		method: 'post',
		url,
		data,
	});
}

function getOrderItemDigitalProducts(webAccountId) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/GetOrderItemDigitalProducts`;
	const params = { webAccountId };

	return axios({
		method: 'get',
		url,
		params,
	});
}

function updateOrderItemDigitalProductActivated(orderId, productId, instanceId, activatedByWebAccountId, firstName, lastName, emailAddress) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/UpdateOrderItemDigitalProductActivated`;

	const data = JSON.stringify({
		orderId,
		productId,
		instanceId,
		activatedByWebAccountId,
		firstName,
		lastName,
		emailAddress,
	});

	return axios({
		method: 'post',
		url,
		data,
	});
}

function updateOrderItemDigitalProductActivatedNotificationSent(orderId, productId, instanceId, activatedNotificationSent) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/UpdateOrderItemDigitalProductActivatedNotificationSent`;

	const data = JSON.stringify({
		orderId,
		productId,
		instanceId,
		activatedNotificationSent,
	});

	return axios({
		method: 'post',
		url,
		data,
	});
}

function getOrderItemDigitalProductByLaunchKey(launchKey) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/GetOrderItemDigitalProductByLaunchKey`;
	const params = { launchKey };

	return axios({
		method: 'get',
		url,
		params,
	});
}

function getOrderInvoice(orderId) {
	const url = `${process.env.VUE_APP_DEAFINITION_API}Store/GetOrderInvoice`;
	const params = { orderId };

	return axios({
		method: 'get',
		url,
		params,
		timeout: 120000 // mitigate cold start issues on the jsreport function
	});
}

export const storeService = {
	getCartItems,
	getProvinces,
	getProducts,
	getProduct,
	saveCartItem,
	removeCartItem,
	clearCart,
	createPayFastOrder,
	getPayFastPaymentUrl,
	getOrders,
	cancelOrderPayment,
	getOrderItemDigitalProducts,
	updateOrderItemDigitalProductActivated,
	updateOrderItemDigitalProductActivatedNotificationSent,
	getOrderItemDigitalProductByLaunchKey,
	getOrderInvoice,
};

export default storeService;
