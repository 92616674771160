<template>
	<main class="relative pt-20 bg-gray-100">
		<div class="max-w-screen-2xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
			<div class="bg-white rounded-lg shadow overflow-hidden">
				<div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
					<aside class="py-6 lg:col-span-2">
						<nav class="space-y-1">
							<div
								@click="changeView(1)"
								:class="[
									viewProfile
										? 'bg-primary-50 border-primary-500 text-primary-700 hover:bg-primary-50 hover:text-primary-700'
										: 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
									'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
								]"
								:aria-current="viewProfile ? 'page' : undefined"
							>
								<UserCircleIcon
									:class="[viewProfile ? 'text-primary-500 group-hover:text-primary-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']"
								/>
								<span class="truncate"> Profile </span>
							</div>
							<div
								@click="changeView(2)"
								:class="[
									viewSettings
										? 'bg-primary-50 border-primary-500 text-primary-700 hover:bg-primary-50 hover:text-primary-700'
										: 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
									'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
								]"
								:aria-current="viewSettings ? 'page' : undefined"
							>
								<CogIcon
									:class="[viewSettings ? 'text-primary-500 group-hover:text-primary-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']"
								/>
								<span class="truncate"> Settings </span>
							</div>
							<div
								@click="changeView(3)"
								:class="[
									viewOrders
										? 'bg-primary-50 border-primary-500 text-primary-700 hover:bg-primary-50 hover:text-primary-700'
										: 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
									'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
								]"
								:aria-current="viewOrders ? 'page' : undefined"
							>
								<CreditCardIcon :class="[viewOrders ? 'text-primary-500 group-hover:text-primary-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-1 mr-3 h-6 w-6']" />
								<span class="truncate"> Orders </span>
							</div>
							<div
								@click="changeView(4)"
								:class="[
									viewCourses
										? 'bg-primary-50 border-primary-500 text-primary-700 hover:bg-primary-50 hover:text-primary-700'
										: 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
									'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
								]"
								:aria-current="viewOrders ? 'page' : undefined"
							>
								<AcademicCapIcon :class="[viewCourses ? 'text-primary-500 group-hover:text-primary-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-1 mr-3 h-6 w-6']" />
								<span class="truncate"> Courses </span>
							</div>
						</nav>
					</aside>
					<AccountProfile
						v-if="viewProfile && profile && !forcePasswordChangeOnNextLogin"
						v-model:submitted="submitted"
						:profile="profile"
						:provinces="provinces"
						@updateProfile="onUpdateProfile"
					/>
					<AccountSettings
						v-if="viewSettings || forcePasswordChangeOnNextLogin"
						v-model:submitted="submitted"
						:forcePasswordChangeOnNextLogin="forcePasswordChangeOnNextLogin"
						@changeEmailAddress="onChangeEmailAddress"
						@changePassword="onChangePassword"
					/>
					<AccountOrders v-if="viewOrders && orders && !forcePasswordChangeOnNextLogin" v-model:submitted="submitted" :orders="orders" @getOrderInvoice="onGetOrderInvoice" />
					<AccountCourses
						v-if="viewCourses && courses && !forcePasswordChangeOnNextLogin"
						v-model:submitted="submitted"
						:courses="courses"
						@activateCourse="onActivateCourse"
						@resendCourseEmail="onResendCourseEmail"
					/>
				</div>
				<div>
					<ErrorMessages :errorMessages="errorMessages" />
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
//icons
import { UserCircleIcon, CogIcon, CreditCardIcon, AcademicCapIcon } from '@heroicons/vue/outline';
//account views
import AccountProfile from '@/views/customer/AccountProfile.vue';
import AccountSettings from '@/views/customer/AccountSettings.vue';
import AccountOrders from '@/views/customer/AccountOrders.vue';
import AccountCourses from '@/views/customer/AccountCourses.vue';
import { webAccountService, storeService } from '../../services';
import { useToast } from 'vue-toastification';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';

export default {
	name: 'AccountMain',
	inject: ['appInsights'],
	components: {
		UserCircleIcon,
		CogIcon,
		CreditCardIcon,
		AcademicCapIcon,
		AccountProfile,
		AccountSettings,
		AccountOrders,
		AccountCourses,
		ErrorMessages,
	},
	data: () => {
		return {
			submitted: false,
			viewProfile: true,
			viewSettings: false,
			viewOrders: false,
			viewCourses: false,
			profile: null,
			provinces: [],
			orders: [],
			courses: [],
			errorMessages: [],
		};
	},
	computed: {
		...mapGetters(['webAccountId', 'forcePasswordChangeOnNextLogin']),
	},
	methods: {
		changeView(index) {
			if (!this.forcePasswordChangeOnNextLogin) {
				this.viewProfile = index == 1;
				this.viewSettings = index == 2;
				this.viewOrders = index == 3;
				this.viewCourses = index == 4;
			}
		},
		onUpdateProfile(profile) {
			this.errorMessages = [];
			this.submitted = true;

			webAccountService
				.updateWebAccount(
					this.webAccountId,
					profile.provinceId,
					profile.firstName,
					profile.lastName,
					profile.dateOfBirth,
					profile.contactNumber,
					profile.addressLine1,
					profile.addressLine2,
					profile.addressLine3,
					profile.postCode,
					profile.companyAccount,
					profile.companyName,
					profile.companyRegistrationNumber,
					profile.vatNumber
				)
				.then(
					(response) => {
						this.submitted = false;

						if (response.data.result) {
							useToast().success('Profile update successful.');

							this.$store.dispatch('updateWebAccountFullName', {
								fullName: response.data.data.fullName,
							});
						} else {
							useToast().error('Profile update failed.');

							this.errorMessages[0] = response.data.message;
						}
					},
					(error) => {
						this.submitted = false;

						useToast().error('Profile update failed.');

						if (error.response && error.response.data && error.response.data.data) {
							this.errorMessages = error.response.data.data.slice();
						}

						this.appInsights.trackException({
							exception: new Error(`Profile update failed:${error.message}`),
						});
					}
				);
		},
		onChangeEmailAddress(emailAddress) {
			this.errorMessages = [];
			this.submitted = true;

			webAccountService.changeWebAccountEmailAddress(this.webAccountId, emailAddress).then(
				(response) => {
					this.submitted = false;

					if (response.data.result) {
						useToast().success('Email address change successful. Logging out.');

						this.$router.push('/login');
					} else {
						useToast().error('Email address change failed.');

						this.errorMessages[0] = response.data.message;
					}
				},
				(error) => {
					this.submitted = false;

					useToast().error('Email address change failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`Email address change failed:${error.message}`),
					});
				}
			);
		},
		onChangePassword(newPassword, confirmNewPassword) {
			this.errorMessages = [];
			this.submitted = true;

			webAccountService.changeWebAccountPassword(this.webAccountId, newPassword, confirmNewPassword).then(
				(response) => {
					this.submitted = false;

					if (response.data.result) {
						if (this.forcePasswordChangeOnNextLogin) {
							useToast().success('Password change successful. Logging out.');

							this.$router.push('/login');
						} else {
							useToast().success('Password change successful.');
						}
					} else {
						useToast().error('Password change failed.');

						this.errorMessages[0] = response.data.message;
					}
				},
				(error) => {
					this.submitted = false;

					useToast().error('Password change failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`Password change failed:${error.message}`),
					});
				}
			);
		},
		onActivateCourse(course) {
			this.errorMessages = [];
			this.submitted = true;

			storeService
				.updateOrderItemDigitalProductActivated(course.orderId, course.productId, course.instanceId, this.webAccountId, course.firstName, course.lastName, course.emailAddress)
				.then(
					(response) => {
						this.submitted = false;

						if (response.data.result) {
							useToast().success('Course activation successful.');

							this.courses = response.data.data;
						} else {
							useToast().error('Course activation failed.');

							this.errorMessages[0] = response.data.message;
						}
					},
					(error) => {
						this.submitted = false;

						useToast().error('Course activation failed.');

						if (error.response && error.response.data && error.response.data.data) {
							this.errorMessages = error.response.data.data.slice();
						}

						this.appInsights.trackException({
							exception: new Error(`Course activation failed:${error.message}`),
						});
					}
				);
		},
		onResendCourseEmail(course) {
			this.errorMessages = [];
			this.submitted = true;

			storeService.updateOrderItemDigitalProductActivatedNotificationSent(course.orderId, course.productId, course.instanceId, false).then(
				(response) => {
					this.submitted = false;

					if (response.data.result) {
						useToast().success('Resend course email successful.');
					} else {
						useToast().error('Resend course email failed.');

						this.errorMessages[0] = response.data.message;
					}
				},
				(error) => {
					this.submitted = false;

					useToast().error('Resend course email failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`Resend course email failed:${error.message}`),
					});
				}
			);
		},
		onGetOrderInvoice(orderId) {
			this.errorMessages = [];
			this.submitted = true;

			storeService.getOrderInvoice(orderId).then(
				(response) => {
					this.submitted = false;

					if (response.data.result) {
						let sliceSize = 512;
						let byteCharacters = window.atob(response.data.data); //convert base64 to binary
						let byteArrays = [];

						for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
							let slice = byteCharacters.slice(offset, offset + sliceSize);
							let byteNumbers = new Array(slice.length);
							for (let i = 0; i < slice.length; i++) {
								byteNumbers[i] = slice.charCodeAt(i);
							}
							let byteArray = new Uint8Array(byteNumbers);
							byteArrays.push(byteArray);
						}

						let blob = new Blob(byteArrays, {
							type: 'application/pdf',
						});

						let link = document.createElement('a');
						link.href = URL.createObjectURL(blob);
						link.download = `Order ${orderId}.pdf`;
						link.click();
					} else {
						useToast().error('Get order invoice failed.');

						this.errorMessages[0] = response.data.message;
					}
				},
				(error) => {
					this.submitted = false;

					useToast().error('Get order invoice failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`Get order invoice failed:${error.message}`),
					});
				}
			);
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'AccountMain' });

		if (!this.forcePasswordChangeOnNextLogin) {
			const parameters = this.$route.query;
			if (parameters.viewOrders && parameters.viewOrders === 'true') {
				this.changeView(3);
			}

			const self = this;
			Promise.all([
				webAccountService.getWebAccount(this.webAccountId),
				storeService.getProvinces(),
				storeService.getOrders(this.webAccountId),
				storeService.getOrderItemDigitalProducts(this.webAccountId),
			])
				.then((responses) => {
					responses.map((response, index) => {
						if (response.data.result) {
							switch (index) {
							case 0:
								this.profile = response.data.data;
								break;
							case 1:
								this.provinces = response.data.data;
								break;
							case 2:
								this.orders = response.data.data;
								break;
							case 3:
								this.courses = response.data.data;
								break;
							}
						} else {
							useToast().error('Error loading account.');
						}
					});
				})
				.catch((error) => {
					useToast().error('Error loading account.');

					self.appInsights.trackException({
						exception: new Error(`AccountMain created:${error.message}`),
					});
				});
		}
	},
};
</script>
