const cartItems = JSON.parse(localStorage.getItem('cartItems'));
const state = { cartItems: cartItems ? cartItems : [] };

const mutations = {
	updateCartItems(state, cartItems) {
		state.cartItems = cartItems;
	},
};

const actions = {
	updateCartItems({ commit }, { cartItems }) {
		localStorage.setItem('cartItems', JSON.stringify(cartItems));
		commit('updateCartItems', cartItems);
	},
};

const getters = {
	cartItems: (state) => state.cartItems,
	cartTotal: (state) => {
		return state.cartItems
			.reduce((sum, cartItem) => {
				return parseFloat(cartItem.priceIncVat + sum);
			}, 0)
			.toFixed(2);
	},
	cartQuantity: (state) => {
		return state.cartItems.reduce((sum, cartItem) => {
			return cartItem.quantity + sum;
		}, 0);
	},
};

export const storeModule = {
	state,
	mutations,
	actions,
	getters,
};

export default storeModule;
