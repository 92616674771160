<template>
	<div class="min-h-screen flex items-center justify-center py-28 px-4 sm:px-6 lg:px-8 overflow-hidden">
		<div class="max-w-md w-full space-y-8">
			<div>
				<div class="mx-auto hidden md:block">
					<a href="/"><img class="object-cover h-12" alt="deafinition logo" src="images/deafinition_text_logo.png" /></a>
				</div>
				<h2 class="mt-4 text-2xl lg:text-3xl font-extrabold text-gray-900">Resend Confirmation Email</h2>
				<div class="mt-1 text-sm text-gray-600">If you did not receive the original Confirm Registration Email or accidentally deleted it.</div>
				<div class="max-w-md w-full space-y-6">
					<form id="resendConfirmRegistrationEmail" class="mt-8 space-y-6" @submit.prevent="submit">
						<TextInput
							autocomplete="on"
							:autofocus="true"
							form="resendConfirmRegistrationEmail"
							:maxlength="150"
							:minlength="5"
							name="emailAddress"
							placeholder="Email Address"
							type="email"
							v-model="emailAddress"
							@update:model-value="emailAddress = $event"
						/>
						<div class="py-1 text-right sm:px-2">
							<button type="submit" :disabled="submitted" class="buttonprimary px-5 py-3">Resend</button>
						</div>
						<div>
							<ErrorMessages :errorMessages="errorMessages" />
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from '../../components/controls/TextInput.vue';
import ErrorMessages from '../../components/controls/ErrorMessages.vue';
import { webAccountService } from '../../services';
import { useToast } from 'vue-toastification';

export default {
	name: 'ResendConfirmRegistrationEmail',
	inject: ['appInsights'],
	components: {
		TextInput,
		ErrorMessages,
	},
	data: () => {
		return {
			submitted: false,
			errorMessages: [],
			emailAddress: null,
		};
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.errorMessages = [];
			this.submitted = true;
			webAccountService
				.resendConfirmRegistrationEmail(this.emailAddress)
				.then((response) => {
					this.submitted = false;

					if (response.data.result) {
						useToast().success('Registration email resent. Redirecting to login.');

						this.$router.push('/login');
					} else {
						useToast().error('Send registration email failed.');

						this.errorMessages[0] = response.data.message;
					}
				})
				.catch((error) => {
					this.submitted = false;

					useToast().error('Send registration email failed.');

					if (error.response && error.response.data && error.response.data.data) {
						this.errorMessages = error.response.data.data.slice();
					}

					this.appInsights.trackException({
						exception: new Error(`Registration email resend failed:${error.message}`),
					});
				});
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'ResendConfirmRegistrationEmail' });
		this.$store.dispatch('loginClear');
	},
};
</script>
