<template>
	<nav class="bg-white border-b fixed flex animated w-full" :class="{ scrolled: !view.atTopOfPage }">
		<!-- Mobile menu, show/hide based on menu state. -->
		<transition
			enter-active-class="transition duration-200 ease-out"
			enter-from-class="translate-x-1 opacity-0"
			enter-to-class="translate-x-0 opacity-100"
			leave-active-class="transition duration-200 ease-in"
			leave-from-class="translate-x-0 opacity-100"
			leave-to-class="translate-x-1 opacity-0"
		>
			<div v-if="showMobileMenu" class="absolute md:hidden pt-20 w-full" id="mobile-menu">
				<div class="grid grid-cols-1 bg-white px-3 py-3 shadow rounded">				
					<div class="pt-2">
						<h3 class="mobile-menu-header">Account</h3>
						<ul>
							<li class="pt-1">
								<a href="/login" class="text-sm tracking-tighter text-gray-500 hover:text-gray-900"> Log In </a>
							</li>
							<li class="pt-1">
								<a href="/Register" class="text-sm tracking-tighter text-gray-500 hover:text-gray-900"> Register </a>
							</li>
							<li class="pt-1">
								<a href="/Register" class="text-sm tracking-tighter text-gray-500 hover:text-gray-900"> Account </a>
							</li>
						</ul>
					</div>				
					<div class="pt-2">
						<h3 class="mobile-menu-header">Contact</h3>
						<ul class="">
							<li v-for="(item, i) in contact" :key="i" class="pt-1">
								<a :href="item.route" class="text-sm tracking-tighter text-gray-500 hover:text-gray-900">
									{{ item.heading }}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</transition>

		<div class="w-full mx-auto px-2 sm:px-6 lg:px-8">
			<div class="relative flex h-20">
				<div class="absolute inset-y-0 left-0 items-center md:hidden mt-5 max-w-xl">
					<!-- Mobile menu button - keep hidden for now at small screens -  all links are navigable on the home page -->
					<button
						type="button"
						class="
							inline-flex
							items-center
							justify-center
							p-2
							rounded-md
							text-gray-400
							hover:text-gray-500 hover:bg-gray-100
							focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500
						"
						aria-controls="mobile-menu"
						aria-expanded="false"
					>
						<span class="sr-only">Open main menu</span>
						<span @click="showMobileMenu = true" v-if="!showMobileMenu" class="h-6 w-6"><MenuIcon /></span>
						<span @click="showMobileMenu = false" v-if="showMobileMenu" class="h-6 w-6"><XIcon /></span>
					</button>
				</div>

				<div class="flex-1 flex items-center justify-center sm:items-stretch md:justify-between">
					<div class="flex-shrink-0 flex items-center">
						<a href="/"><img class="h-14 pr-2 hidden lg:block" alt="Deafinition logo" src="images/deafinition_logo_pic.png" /></a>
						<a href="/"><img class="h-16" alt="Deafinition logo" src="images/saslonline_text_logo.png" /></a>
					</div>
					<div class="hidden md:flex space-x-4 xl:space-x-10 mt-2">						
						<div>
							<button v-if="!loggedIn && currentRouteName !== 'Register'" @click="onSignUp()" class="whitespace-nowrap buttonprimary text-sm xl:text-base mt-4">Sign up</button>
						</div>
						<div class="mt-6">
							<button v-if="!loggedIn && currentRouteName !== 'LogIn'" @click="onSignIn()" class="menu-button">Sign in</button>
						</div>
						<div class="mt-6">
							<button type="button" class="menu-button">
								<router-link :to="{ path: '/Store' }">Store</router-link>
							</button>
						</div>
						<div class="mt-6">
							<button type="button" class="menu-button">
								<router-link :to="{ path: '/contact' }">Contact</router-link>
							</button>
						</div>
						<div v-if="loggedIn" class="my-auto">
							<UserMenu :fullName="fullName" :class="cartQuantity == 0 ? 'md:mr-0 lg:mr-12' : ''" />
						</div>
						<div v-if="loggedIn && cartQuantity > 0" @click="onCartClick()" class="my-auto flex items-center cursor-pointer ml-1">
							<ShoppingCartIcon class="h-6 text-gray-500" />
							<div class="text-xs font-semibold tracking-tight rounded-full bg-blue-200 text-blue-700 ml-1 py-2 px-3 flex">
								<span class="sm:mr-1">{{ cartQuantity }}</span>
								<span class="hidden md:block">items</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMenu from '@/components/controls/UserMenu.vue';
import { MenuIcon, XIcon, ShoppingCartIcon } from '@heroicons/vue/outline';
import site_data from '@/assets/data/site-data';

export default {
	name: 'Navbar',
	inject: ['appInsights'],
	components: {
		UserMenu,
		MenuIcon,
		XIcon,
		ShoppingCartIcon,
	},
	data: () => {
		return {
			showMobileMenu: false,
			services: site_data.Services,
			team: site_data.Team,
			contact: site_data.Contact,
			view: {
				atTopOfPage: true,
			},
		};
	},
	computed: {
		...mapGetters(['loggedIn', 'webAccountId', 'fullName', 'cartQuantity']),
		currentRouteName() {
			return this.$router.currentRoute.value.name;
		},
	},
	methods: {
		onSignIn() {
			this.$router.push('/login');
		},
		onSignUp() {
			this.$gtag.event('navbar-sign-up', { method: 'Google' })
			this.$router.push('/register');
		},
		onCartClick() {
			this.$router.push('/cart');
		},
		// the function to call when the user scrolls, added as a method
		handleScroll() {
			// when the user scrolls, check the pageYOffset
			if (window.pageYOffset > 0) {
				// user is scrolled
				if (this.view.atTopOfPage) this.view.atTopOfPage = false;
			} else {
				// user is at top of page
				if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
			}
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'Navbar' });
		this.services = site_data.Services;
	},
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll);
	},
};
</script>

<style scoped>
nav {
	z-index: 100;
}

nav.scrolled {
	@apply shadow;
	border-bottom: 0px;
}
</style>
