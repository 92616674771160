<template>
	<div class="min-h-screen pt-24 px-4 max-w-7xl mx-auto">
		<h2 class="text-xl lg:text-3xl font-extrabold text-gray-900">Registration Completed.</h2>
	</div>
</template>

<script>
import { webAccountService } from '../../services';
import { useToast } from 'vue-toastification';

export default {
	name: 'CompleteRegistration',
	inject: ['appInsights'],
	methods: {
		completeRegistration(key) {
			webAccountService
				.completeRegistration(key)
				.then((response) => {
					if (response.data.result) {
						useToast().success('Registration completed. Redirecting to login.');

						this.$router.push('/login');
					} else {
						useToast().error('Registration failed. Redirecting to login.');

						this.$router.push('/login');
					}
				})
				.catch((error) => {
					useToast().error('Registration failed. Redirecting to login.');

					this.appInsights.trackException({
						exception: new Error(`completeRegistration:${error.message}`),
					});

					this.$router.push('/login');
				});
		},
	},
	created() {
		this.appInsights.trackPageView({ name: 'CompleteRegistration' });

		const parameters = this.$route.query;
		if (parameters.key) {
			this.completeRegistration(parameters.key);
		} else {
			useToast().error('Registration failed. Redirecting to login.');

			this.$router.push('/login');
		}
	},
};
</script>
